/* eslint react-hooks/exhaustive-deps: 2 */

import React from 'react'

const getPersistedFilter = <T extends object>(key: string): T | undefined => {
  const rawItem = localStorage.getItem(key)
  if (!rawItem) {
    return
  }

  try {
    return JSON.parse(rawItem)
  } catch (error) {
    return
  }
}

const persistFilter = (key: string, value: unknown): void => {
  localStorage.setItem(key, JSON.stringify(value))
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useFilters = () => {
  const [dateFilter, setDateFilter] = React.useState<[Date, Date] | undefined>(
    getPersistedFilter('date'),
  )

  // Simple persistance of the filter until they are persisted in a view in the database
  React.useEffect(() => {
    persistFilter('date', dateFilter)
  }, [dateFilter])

  return {
    dateFilter,
    setDateFilter,
  }
}
