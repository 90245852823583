import { useLocation } from 'react-router'

/**
 * Hook to show/hide features
 * By default, features are hidden in production and shown in staging, review envs and development
 * To show features in production, add ?preview=true to the URL
 * @returns {boolean} isPreview
 */
const usePreview = (): { isPreview: boolean } => {
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)

  const isProductionOrStaging = [
    'app.staging.cozero.io',
    'app.cozero.io',
    'edelivery.cozero.io',
  ].includes(window.location.hostname)
  const searchParamPreview = searchParams.get('preview')
  const isPreviewParam = searchParamPreview === 'true'

  // We might want to force preview=false, so if searchParamPreview is defined,it takes prirority over isProduction
  const isPreview = searchParamPreview ? isPreviewParam : !isProductionOrStaging

  return { isPreview }
}

export default usePreview
