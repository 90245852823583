import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineClockCircle } from 'react-icons/ai'
import { HiOutlineBookOpen } from 'react-icons/hi'
import { Outlet, useLocation, useNavigate } from 'react-router'

import { Col, Row, Tabs } from 'antd/es'

import { routes } from '@cozero/utils'

import Button from '@/atoms/Button'
import Pill from '@/atoms/Pill'
import Text from '@/atoms/Text'
import Title from '@/atoms/Title'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useFiltersContext } from '@/contexts/filters'
import usePreview from '@/hooks/usePreview'
import { useAppSelector } from '@/redux'
import { getIsManagerOrAdmin } from '@/redux/auth'

import classes from './CarbonFootprint.module.less'

const { TabPane } = Tabs

function CarbonFootprint(): JSX.Element {
  const navigate = useNavigate()
  const isManagerOrAdmin = useAppSelector(getIsManagerOrAdmin)
  const { pathname } = useLocation()
  const { t } = useTranslation('common')
  const [hideSettledPeriod, setHideSettledPeriod] = useState(false)
  const { isPreview } = usePreview()

  const { filters, resetSearch } = useFiltersContext()

  /** We need to reset the filters because they are currently global and applied across views. */
  useEffect(() => {
    // Resetting filters on component dismount
    return () => {
      if (filters?.length) {
        resetSearch()
      }
    }
  }, [])

  const MENU_ITEMS = useMemo(() => {
    return [
      {
        uri: `${routes.log.carbonFootprint.organization.base}`,
        tabTitle: t('log.carbon-footprint-tabs.organization'),
        title: t('log.locations-title'),
        subtitle: t('log.locations-subtitle'),
        cy: 'orgainzation-tab',
        disabled: false,
        logType: 'location',
      },
      {
        uri: `${routes.log.carbonFootprint.products.base}`,
        tabTitle: t('log.carbon-footprint-tabs.products'),
        title: t('log.products-title'),
        subtitle: t('log.products-subtitle'),
        cy: 'products-tab',
        disabled: false,
        logType: 'product',
      },
    ]
  }, [filters])

  const TabsItems = useMemo(() => {
    return MENU_ITEMS.map(({ uri, tabTitle, disabled, cy }) => (
      <TabPane
        key={uri}
        tab={
          <span data-cy={cy} className={classes.tabPane}>
            {tabTitle} {disabled && <Pill>{t('log.soon')}</Pill>}
          </span>
        }
        disabled={disabled}
      >
        <Outlet />
      </TabPane>
    ))
  }, [MENU_ITEMS, pathname])

  const [currentTab, setCurrentTab] = useState<{
    uri: string
    title: string
    subtitle: string
    logType: string
    cy?: string
  }>(MENU_ITEMS[0])

  const onTabClickHandler = useCallback((key: string): void => {
    resetSearch()
    setCurrentTab(MENU_ITEMS.find((item) => item.uri === key) ?? MENU_ITEMS[0])
    return navigate(key)
  }, [])

  useEffect(() => {
    setHideSettledPeriod(!pathname.includes('organization'))
    if (location) {
      setCurrentTab(MENU_ITEMS.find((item) => item.uri === location.pathname) ?? MENU_ITEMS[0])
    }
  }, [pathname])

  return (
    <Row justify="center">
      <Col span={24}>
        <Row justify="space-between" align="bottom">
          <Col span={12}>
            <Title data-cy="emission-log-title" as="h1">
              {currentTab.title}
            </Title>
          </Col>
        </Row>
        <Row>
          <Col xl={12} lg={16} span={24} className={classes.subtitle}>
            <Text size="xl" color="secondary">
              {currentTab.subtitle}
            </Text>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Button
              prefixIcon={<HiOutlineBookOpen size={20} />}
              href={t(`intercom.carbonFootprint.${currentTab.logType}`)}
              type="link"
              target="_blank"
              rel="noreferrer"
              className={classes.learnLink}
            >
              {t(`log.${currentTab.logType}-learn-title`)}
            </Button>
          </Col>
        </Row>
      </Col>
      <Col span={24} className={classes.tabsWrapper}>
        <Tabs
          tabBarExtraContent={
            !isPreview &&
            isManagerOrAdmin && (
              <Button
                style={{ display: hideSettledPeriod ? 'none' : 'flex' }}
                prefixIcon={<AiOutlineClockCircle />}
                action="Closed period"
                category={AnalyticsCategories.LOCATIONS}
                onClick={() => {
                  resetSearch()
                  navigate(routes.log.carbonFootprint.addClosedPeriod)
                }}
              >
                {t('log.carbon-footprint-tabs.closed-period')}
              </Button>
            )
          }
          activeKey={currentTab.uri}
          defaultActiveKey={MENU_ITEMS[0].uri}
          onTabClick={onTabClickHandler}
        >
          {TabsItems}
        </Tabs>
      </Col>
    </Row>
  )
}

export default CarbonFootprint
