import { ViteImportMeta } from '../types/vite'

const envVar = (import.meta as ViteImportMeta).env

const env = {
  API_URL: envVar.VITE_API_URL,
  NODE_ENV: envVar.VITE_NODE_ENV,
  MIXPANEL_ID: envVar.VITE_MIXPANEL_ID,
  HUBSPOT_FORM_KEY: envVar.VITE_HUBSPOT_FORM_KEY,
  HUBSPOT_FORM_PORTAL_ID: envVar.VITE_HUBSPOT_FORM_PORTAL_ID,
  NEW_RELIC_LICENSE_KEY: envVar.VITE_NEW_RELIC_LICENSE_KEY,
  NEW_RELIC_ACCOUNT_ID: envVar.VITE_NEW_RELIC_ACCOUNT_ID,
  NEW_RELIC_APPLICATION_ID: envVar.VITE_NEW_RELIC_APPLICATION_ID,
  NEW_RELIC_RELEASE_ID: envVar.VITE_NEW_RELIC_RELEASE_ID,
  NEW_RELIC_RELEASE_NAME: envVar.VITE_NEW_RELIC_RELEASE_NAME,
}

export const languages = [
  { id: 'en', name: 'English' },
  { id: 'de', name: 'Deutsch' },
  { id: 'ja', name: 'Japanese / 日本語' },
]

export const config = {
  ...env,
  routes: {
    home: '/',
    dashboard: '/',
    callback: '/callback',
    logout: '/logout',
    expiredToken: '/expired-token',
    onboarding: '/onboarding',
    onboardingSupplierStep2: '/onboarding/supplier-step-2',
    onboardingSupplierStep1: '/onboarding/supplier-step-1',
    activate: '/activate',
    faq: '/faq',
    support: '/support',
    account: '/account',
    checkout: {
      paymentSuccess: '/checkout/success',
      paymentCancel: '/checkout/cancel',
    },
    login: {
      base: '/login',
      start: '/login',
      finish: '/login/finish',
    },
    register: {
      base: '/register',
      start: '/register',
      user: '/register/activate',
      organization: '/register/organization',
    },
    overview: {
      base: '/',
      dashboard: '/',
      reports: '/reports/',
      viewReport: '/reports/:boardKey',
    },
    log: {
      base: '/log',
      tags: '/log/tags',
      productLogs: '/log/products',
      customReport: '/log/closed-periods/:id',
      carbonFootprint: {
        base: '/log/carbon-footprint',
        organization: {
          base: '/log/carbon-footprint/organization',
          edit: '/log/carbon-footprint/organization/:id',
        },
        products: {
          base: '/log/carbon-footprint/products',
          create: '/log/carbon-footprint/products/create',
          productDetails: '/log/carbon-footprint/products/:productId',
          edit: '/log/carbon-footprint/products/:id/edit',
          logs: '/log/carbon-footprint/products/:id/logs',
        },
        productsUpsert: {
          base: '/log/carbon-footprint/products/flow',
          stepsCreate: '/log/carbon-footprint/products/flow/create/steps',
          stepsEdit: '/log/carbon-footprint/products/flow/:id/edit/steps',
          configurationCreate: '/log/carbon-footprint/products/flow/create/configuration',
          configurationEdit: '/log/carbon-footprint/products/flow/:id/edit/configuration',
          quantitiesCreate: '/log/carbon-footprint/products/flow/create/quantities',
          quantitiesEdit: '/log/carbon-footprint/products/flow/:id/edit/quantities',
          rawMaterialsAcquisitionCreate:
            '/log/carbon-footprint/products/flow/create/raw-materials-acquisition',
          rawMaterialsAcquisitionEdit:
            '/log/carbon-footprint/products/flow/:id/edit/raw-materials-acquisition',
          productionCreate: '/log/carbon-footprint/products/flow/create/production',
          productionEdit: '/log/carbon-footprint/products/flow/:id/edit/production',
          distributionAndStorageCreate:
            '/log/carbon-footprint/products/flow/create/distribution-and-storage',
          distributionAndStorageEdit:
            '/log/carbon-footprint/products/flow/:id/edit/distribution-and-storage',
          endOfLifeCreate: '/log/carbon-footprint/products/flow/create/end-of-life',
          endOfLifeEdit: '/log/carbon-footprint/products/flow/:id/edit/end-of-life',
          packagingCreate: '/log/carbon-footprint/products/flow/create/packaging',
          packagingEdit: '/log/carbon-footprint/products/flow/:id/edit/packaging',
          usageCreate: '/log/carbon-footprint/products/flow/create/usage',
          usageEdit: '/log/carbon-footprint/products/flow/:id/edit/usage',
        },
        productsUpdate: {
          base: '/log/carbon-footprint/products/:id/update',
          steps: '/log/carbon-footprint/products/:id/update/steps',
          configuration: '/log/carbon-footprint/products/:id/update/configuration',
          quantities: '/log/carbon-footprint/products/:id/update/quantities',
        },
        quantities: '/log/carbon-footprint/quantities',
        sales: '/log/carbon-footprint/sales',
        addClosedPeriod: '/log/carbon-footprint/closed-period',
        editClosedPeriod: '/log/carbon-footprint/closed-period/:id',
      },
      quantitiesLogs: '/log/quantities',
      bulkImport: {
        base: '/log/bulk-import/',
        create: '/log/bulk-import/create',
        list: '/log/bulk-import/list',
      },
      pastBulkImports: '/log/bulk-import/past-imports',
      reports: '/log/reports/',
      viewReport: '/log/reports/:boardKey',
      editLog: '/log/:id',
      factors: {
        base: '/log/factors',
        emissions: '/log/factors/emissions',
        requestsPage: '/log/factors/requests',
        request: '/log/factors/request',
        calculations: {
          base: '/log/factors/calculations',
          viewCalculation: '/log/factors/calculations/:id',
        },
      },
      suppliers: '/log/suppliers',
      'closed-periods': '/log/closed-periods',
      'customer-requests': '/log/customer-requests',
      customers: '/log/customers',
    },
    act: {
      base: '/act',
      marketplace: '/act/marketplace',
      basket: '/act/marketplace/basket',
      requests: '/act/marketplace/requests',
      request: '/act/marketplace/request/:id',
      climateActions: '/act/climate-actions',
      createClimateAction: '/act/climate-actions/new',
      editClimateAction: '/act/climate-actions/:id/edit',
      viewClimateAction: '/act/climate-actions/:id',
      inquiries: '/act/inquiries',
      actionAreas: '/act/actions-areas',
      targets: '/act/targets',
      newTarget: '/act/targets/new',
      editTarget: '/act/targets/:id',
      solutions: '/act/solutions/:key',
      solutionsSearch: '/act/solutions',
      solution: '/act/marketplace/:id',
      offersPage: '/act/:id/offers',
      services: '/act/services',
      forecastSettings: '/act/forecast-settings',
      editForecastSettings: '/act/forecast-settings/:id',
    },
    share: {
      base: '/share',
      createStory: '/share/story/new',
      successStory: '/share/story',
      story: '/share/story/:storyId',
      addStory: '/share/story/new',
      editStory: '/share/story/:storyId/edit',
      stories: '/share/stories',
      certifications: '/share/certifications',
      sustainabilityReports: '/share/sustainability-reports',
      sharePages: '/share/share-pages',
      viewSharePage: '/share/share-pages/:slug',
    },
    organization: {
      base: '/log/organization',
      locations: {
        base: '/log/organization/locations',
        singleLocation: '/log/organization/locations/:locationId',
        logs: '/log/organization/locations/:locationId/logs',
        editLog: '/log/organization/locations/:locationId/logs/:id',
      },
      addLocation: '/log/organization/location',
      editLocation: '/log/organization/locations/:id/edit',
      businessUnits: '/log/organization/business-units',
      addBusinessUnit: '/log/organization/business-unit',
      editBusinessUnit: '/log/organization/business-units/:id',
      lifecycleSteps: '/log/organization/products/lifecycle-steps',
      organigram: '/log/organization/organigram',
    },
    settings: {
      base: '/settings',
      organization: '/settings/organization',
      data: '/settings/data',
      api: '/settings/api',
      functions: '/settings/functions',
      subscription: '/settings/subscription',
      plans: '/settings/plans',
      users: '/settings/users',
      editUser: '/settings/user/:id',
      addUser: '/settings/user/',
      profile: '/settings/profile',
      integrations: '/settings/integrations',
      installIntegration: '/settings/integrations/:id',
    },
    password: {
      base: '/password',
      requestReset: '/password/request',
      submitReset: '/password/reset',
    },
  },
  languages,
} as const

export const formatColumns = {
  number: [
    'CompanyCalculationsDbt.carbonValue',
    'CompanyCalculationsDbt.carbonPerEmployee',
    'CompanyCalculationsDbt.consumptionValue',
    'ProductEmissionsDbt.carbonValue',
    'ProductEmissionsDbt.carbonValueInG',
    'ProductQuantitiesDbt.volume',
    'ProductEmissionsQuantitiesDbt.carbonValueVolume',
  ],
  date_month: [
    'Quantities.startDate.month',
    'Quantities.endDate.month',
    'CompanyCalculationsDbt.timestamp.month',
    'ProductQuantitiesDbt.timestamp.month',
    'ProductEmissionsQuantitiesDbt.timestamp.month',
    'CarbonIntensities.timestamp.month',
  ],
  date_year: [
    'Quantities.startDate.year',
    'Quantities.endDate.month',
    'CompanyCalculationsDbt.timestamp.year',
    'ProductQuantitiesDbt.timestamp.year',
    'ProductEmissionsQuantitiesDbt.timestamp.year',
    'CarbonIntensities.timestamp.year',
  ],
  date_quarter: [
    'CompanyCalculationsDbt.timestamp.quarter',
    'ProductQuantitiesDbt.timestamp.quarter',
    'ProductEmissionsQuantitiesDbt.timestamp.quarter',
    'CarbonIntensities.timestamp.quarter',
  ],
}

export const tabKeys = {
  '': '1',
  'organization-products': '2',
  'organization-business-units': '2',
  'organization-locations': '2',
  'log-locations': '3',
  'log-products': '4',
  'log-reports': '5',
  'log-factors': '18',
  customers: '6',
  suppliers: '7',
  act: '8',
  'share-share-pages': '9',
  'share-stories': '10',
  'share-certifications': '11',
  'share-sustainability-reports': '12',
  settings: '14',
  'settings-organization': '14',
  'settings-plans': '14',
  'settings-subscription': '14',
  'settings-api': '14',
  'settings-data': '14',
  'settings-function': '14',
} as const

export const menuKeys = {
  '1': '',
  '2': 'sub1',
  '3': 'sub1',
  '4': 'sub1',
  '5': 'sub1',
  '6': 'sub1',
  '7': 'sub1',
  '18': 'sub1',
  '8': '',
  '9': 'sub2',
  '10': 'sub2',
  '11': 'sub2',
  '12': 'sub2',
  '13': '',
  '14': '',
} as const

export const supportUrl = 'https://cozero.io/wiki/reach-out-to-our-support/'

export const dateFormat = 'lll'

export const maxUploadBytes = 50000000
