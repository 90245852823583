import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineClockCircle } from 'react-icons/ai'
import { HiInformationCircle, HiOutlinePlus } from 'react-icons/hi'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { Col, Row, Tabs, message } from 'antd/es'
import TabPane from 'antd/es/tabs/TabPane'

import { isEqual } from 'lodash-es'

import { CompanyEmissionOverviewDTO, CreateLogDto } from '@cozero/dtos'
import { Organization, PageFilter } from '@cozero/models'
import { routes } from '@cozero/utils'

import LogCategoryModal from '@/organisms/LogCategoryModal'
import { EmptyStateCard } from '@/organisms/Onboarding/EmptyStateCard'
import OverviewRow from '@/organisms/OverviewRow'
import ResourceCenterCard from '@/organisms/ResourceCenterCard'

import { OverviewCardProps } from '@/molecules/OverviewCard'

import Alert from '@/atoms/Alert'
import Button from '@/atoms/Button'
import HighlightValue from '@/atoms/HighlightValue'
import Pill from '@/atoms/Pill'
import Text from '@/atoms/Text'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useFiltersContext } from '@/contexts/filters'
import { useLogContext } from '@/contexts/log'
import { useSubscriptionContext } from '@/contexts/subscription'
import useLog from '@/hooks/useLog'
import useMemoCompare from '@/hooks/useMemoCompare'
import { useMixpanel } from '@/hooks/useMixpanel'
import usePollingEffect from '@/hooks/usePollingEffect'
import usePreview from '@/hooks/usePreview'
import { useAppSelector } from '@/redux'
import {
  getFeaturesAllowed,
  getIsManagerOrAdmin,
  getIsUserReadOnly,
  selectUserOrganization,
} from '@/redux/auth'
import { isAxiosError } from '@/redux/axiosBaseQuery'
import { selectSelectedBusinessUnit } from '@/redux/businessUnits'
import { useGetCategoriesQuery } from '@/redux/categories'
import { useCreateLogMutation, useHasLogEntriesQuery } from '@/redux/logs'
import { hasFinishedOnboardingStep } from '@/redux/onboarding'
import { COZERO_BLUE_80 } from '@/styles/variables'
import { createQueryFilter } from '@/utils/filters'
import { formatNumber } from '@/utils/number'

import classes from './Log.module.less'
import { LogEntries } from './LogEntries'
import LogGroups from './LogGroups'

function LocationLogs(): JSX.Element {
  const { t } = useTranslation('common')
  const { isPreview } = usePreview()
  const selectedBusinessUnit = useAppSelector(selectSelectedBusinessUnit)
  const featuresAllowed = useAppSelector(getFeaturesAllowed)
  const userIsReadOnly = useAppSelector(getIsUserReadOnly)
  const organization = useAppSelector(selectUserOrganization) as Organization
  const { getBusinessUnitOverview } = useLog()
  const [loadingLogCreation, setLoadingLogCreation] = useState(false)
  const [overviewStats, setOverviewStats] = useState<CompanyEmissionOverviewDTO | undefined>()
  const [overviewLoading, setOverviewLoading] = useState(false)
  const isManagerOrAdmin = useAppSelector(getIsManagerOrAdmin)
  const [hideSettledPeriod, setHideSettledPeriod] = useState(false)
  const { pathname, search } = useLocation()
  const { trackAction } = useMixpanel()

  const { filters, pageNumber: currentPage, saveFilters, resetSearch } = useFiltersContext()
  const memoedFilters = useMemoCompare<Omit<PageFilter, 'options'>[] | undefined>(
    filters,
    (
      prevFilters: Omit<PageFilter, 'options'>[] | undefined,
      nextFilters: Omit<PageFilter, 'options'>[] | undefined,
    ) => isEqual(prevFilters, nextFilters),
  )

  const hasFinishedLogOnboardingStep = useAppSelector((state) =>
    hasFinishedOnboardingStep(state, 'logs'),
  )
  const { setSubscribeModalSettings, getLimit } = useSubscriptionContext()
  const { data: categories } = useGetCategoriesQuery()
  const [createLog] = useCreateLogMutation()

  const { data: hasLogEntries, isLoading: isLoadingHasLogEntries } = useHasLogEntriesQuery(
    { businessUnitId: selectedBusinessUnit?.id },
    { skip: !selectedBusinessUnit },
  )
  const { loading: logLoading } = useLogContext()
  const [modalOpen, setModalOpen] = useState(false)
  const navigate = useNavigate()

  const { locationId } = useParams()
  const queryLocation = new URLSearchParams(search).get('location') || locationId
  const onChooseCategory = async (id: number): Promise<void> => {
    setLoadingLogCreation(true)
    try {
      await submitLog({ categoryId: id, type: 'location' })
    } finally {
      setLoadingLogCreation(false)
    }
  }

  const overviewData: OverviewCardProps[] = [
    {
      content: (
        <div className={classes.cardContent}>
          <HighlightValue
            value={
              overviewStats?.emissionsDataValue
                ? formatNumber(overviewStats?.emissionsDataValue).toString()
                : '-'
            }
            unit={t('co2-tonnes')}
          />
          <Row>
            <Pill>
              <span className={classes.logsPercentage}>{overviewStats?.percentageOfLogs}%</span>
              <span className={classes.totalLogsPill}>
                {' '}
                {t('log.overview-stats.of')} {overviewStats?.totalLogs} Logs
              </span>
            </Pill>
          </Row>
        </div>
      ),
      headerTitle: t('log.overview-stats.organization-footprint'),
      tooltip: {
        triggerElement: <HiInformationCircle color={COZERO_BLUE_80} />,
        subtitle: t('log.overview-stats.organization-footprint-info'),
      },
    },
  ]

  const submitLog = async (newLog: Partial<CreateLogDto>): Promise<void> => {
    try {
      const createdLog = await createLog(newLog).unwrap()
      if (createdLog) {
        const category = categories?.find((obj) => obj.id === newLog.categoryId)
        message.success(t('log.create-success', { category: category?.name }))
        return navigate(
          `${routes.log.carbonFootprint.organization.edit.replace(
            ':id',
            createdLog.id.toString(),
          )}`,
          {
            state: { firstLog: !hasLogEntries || !hasFinishedLogOnboardingStep },
          },
        )
      }
    } catch (e) {
      if (isAxiosError(e)) {
        if (e.status === 402) {
          const limit = getLimit(organization, 'log')
          setSubscribeModalSettings({
            closable: true,
            title: t('subscription.upgrade-modal.title-logs-limit', {
              limit: limit?.max,
              item: t('product.logs.title'),
            }),
            visible: true,
          })
        } else {
          message.error(t('location.errors.log-creation'))
        }
      } else {
        message.error(t('location.errors.log-creation'))
      }
    }
  }

  const onCloseModal = (): void => {
    setModalOpen(false)
  }

  const openModal = async (): Promise<void> => {
    setModalOpen(true)
  }

  const goToBulkLog = (): void => {
    navigate(routes.log.bulkImport.create)
  }

  const goToBulkLogCreation = (): void => {
    navigate(routes.log.bulkImport.create)
  }

  useEffect(() => {
    if (queryLocation && currentPage === 1) {
      const queryFilter = createQueryFilter({
        value: [queryLocation],
        type: 'location',
        t,
        options: [],
      })
      saveFilters(queryFilter)
      navigate(location.pathname)
    }
  }, [currentPage, queryLocation, selectedBusinessUnit?.key])

  const updateOverviewStatsState = useCallback((overviewData: CompanyEmissionOverviewDTO) => {
    setOverviewStats(overviewData)
    setOverviewLoading(false)
    setIsPollingEnabled(false)
  }, [])

  useEffect(() => {
    const businessUnitOverviewFetch = async (): Promise<void> => {
      if (selectedBusinessUnit) {
        setOverviewLoading(true)
        try {
          setIsPollingEnabled(false)
          const overviewStats = await getBusinessUnitOverview(
            selectedBusinessUnit.id,
            memoedFilters,
          )
          if (overviewStats) {
            updateOverviewStatsState(overviewStats)
          } else {
            setIsPollingEnabled(true)
          }
        } catch (error) {
          setOverviewLoading(false)
          setIsPollingEnabled(false)
        }
      }
    }

    businessUnitOverviewFetch()
  }, [filters, selectedBusinessUnit?.key])

  const { setIsPollingEnabled } = usePollingEffect(
    async () => {
      try {
        if (selectedBusinessUnit) {
          const overviewStats = await getBusinessUnitOverview(
            selectedBusinessUnit.id,
            memoedFilters,
          )
          if (overviewStats) {
            updateOverviewStatsState(overviewStats)
          }
        }
      } catch (error) {
        setIsPollingEnabled(false)
        setOverviewLoading(false)
      }
    },
    [],
    {},
  )

  const onTabClick = React.useCallback(
    (tabKey: string) => {
      trackAction(AnalyticsCategories.LOG_ENTRY_OVERVIEW, `tab-switch-to-${tabKey}`)
    },
    [trackAction],
  )

  useEffect(() => {
    setHideSettledPeriod(!pathname.includes('organization'))
  }, [pathname])

  const isLoading = logLoading || isLoadingHasLogEntries

  if (!hasLogEntries && !isLoading) {
    return (
      <>
        <Row className={classes.logEmptyState}>
          <Col span={24}>
            <EmptyStateCard
              title={t('log.onboarding.title')}
              description={t('log.onboarding.description')}
              buttonText={t('log.onboarding.create-log')}
              type="logs"
              onClick={openModal}
            />
          </Col>
          <ResourceCenterCard title={t('onboarding.demo.resource-card-title')} />
        </Row>
        <LogCategoryModal
          open={modalOpen}
          onChooseCategory={onChooseCategory}
          onClose={onCloseModal}
          loadingLogCreation={loadingLogCreation}
        />
      </>
    )
  }

  return (
    <>
      <Row className={classes.section} align="stretch" justify="space-between">
        {overviewData !== null && (
          <Col>
            <OverviewRow
              hideTitle={isPreview}
              loadingOverview={!!overviewLoading}
              marginBetween={14}
              overviewData={overviewData}
              classNameTitle={!isPreview ? classes.overViewCardTitle : undefined}
            />
          </Col>
        )}

        <Col className={isPreview ? undefined : classes.actionsWrapper}>
          <Row gutter={16} justify="space-between" align="bottom">
            {!isPreview && (
              <Col span={16}>
                <Alert type={'info'} className={classes.alert}>
                  <Text size="xl">{t('log.filter-warning')}</Text>
                </Alert>
              </Col>
            )}
            <Col>
              <Row gutter={16}>
                {isPreview && isManagerOrAdmin && (
                  <Col>
                    <Button
                      style={{ display: hideSettledPeriod ? 'none' : 'flex' }}
                      prefixIcon={<AiOutlineClockCircle />}
                      action="Closed period"
                      category={AnalyticsCategories.LOCATIONS}
                      onClick={() => {
                        resetSearch()
                        navigate(routes.log.carbonFootprint.addClosedPeriod)
                      }}
                    >
                      {t('log.carbon-footprint-tabs.closed-period')}
                    </Button>
                  </Col>
                )}
                {!userIsReadOnly && featuresAllowed.includes('bulk-create') && (
                  <Col>
                    <Button
                      category={AnalyticsCategories.LOGS}
                      action={'add-bulk'}
                      type="primary"
                      onClick={goToBulkLogCreation}
                      className={classes.createButton}
                      data-cy="new-emission-log"
                      prefixIcon={<HiOutlinePlus />}
                    >
                      {t(`log.create.bulk`)}
                    </Button>
                  </Col>
                )}
                {!userIsReadOnly && featuresAllowed.includes('bulk-import') && (
                  <Col>
                    <Button
                      category={AnalyticsCategories.LOGS}
                      action="go-to-bulk-import"
                      type="secondary"
                      className={classes.bulkImportButton}
                      onClick={goToBulkLog}
                      data-cy="bulk-import-btn"
                      prefixIcon={<HiOutlinePlus />}
                    >
                      {t('log.bulkImport')}
                    </Button>
                  </Col>
                )}
                {!userIsReadOnly && (
                  <Col>
                    <Button
                      category={AnalyticsCategories.LOGS}
                      action={'open-create-modal'}
                      type="primary"
                      onClick={openModal}
                      className={classes.createButton}
                      data-cy="new-emission-log"
                      prefixIcon={<HiOutlinePlus />}
                    >
                      {t(`log.create.title`)}
                    </Button>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      {isPreview ? (
        <Tabs className={classes.logTabs} onTabClick={onTabClick}>
          <TabPane
            tab={
              <span className={classes.logTabPane}>
                {t('log.log-entries-overview.tab-title')}
                <Pill size="sm" className={classes.logTabPanePill}>
                  {t('new')}
                </Pill>
              </span>
            }
            key="leo"
          >
            <LogEntries />
          </TabPane>
          <TabPane
            tab={<span className={classes.logTabPane}>{t('log.logs')}</span>}
            key="log-overview"
          >
            <LogGroups />
          </TabPane>
        </Tabs>
      ) : (
        <LogGroups />
      )}
      <LogCategoryModal
        open={modalOpen}
        onChooseCategory={onChooseCategory}
        loadingLogCreation={loadingLogCreation}
        onClose={onCloseModal}
      />
    </>
  )
}

export default LocationLogs
