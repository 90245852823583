import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Col, FormInstance, Space } from 'antd'

import _ from 'lodash'

import { ActivityDataSource, ActivityDataSourceWithChildren, Supplier, Unit } from '@cozero/models'

import { LifecycleStepsKey } from '@/pages/Organization/NewProduct/steps'

import RequestDetails from '@/molecules/RequestDetails'
import SupplierDetails from '@/molecules/SupplierDetails'
import SupplierExtraInformation from '@/molecules/SupplierExtraInformation'

import Form from '@/atoms/Form'
import { SelectOptionsProperties } from '@/atoms/Select'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useAppContext } from '@/contexts/app'
import { useLogContext } from '@/contexts/log'
import useTerritories from '@/hooks/useTerritories'
import { useAppSelector } from '@/redux'
import { getFeaturesAllowed } from '@/redux/auth'
import { CascaderNode } from '@/types/general'

import AddProductModal from '../AddProductModal'
import SuppliersModal from '../SuppliersModal'

import classes from './FactorRequestForm.module.less'
import { FormField, FormValues, InitialFormData } from './types'

interface FactorRequestFormProps {
  initialValues: InitialFormData[]
  setIsFormValid: React.Dispatch<React.SetStateAction<boolean>>
  getFormValues: (values: FormValues) => void
}

const REQUIRED_FIELDS: FormField[] = [
  'email',
  'activityDataSourceId',
  'denominatorUnitId',
  'dueDate',
  'year',
]

const FactorRequestForm = ({
  initialValues,
  setIsFormValid,
  getFormValues,
}: FactorRequestFormProps): JSX.Element => {
  const { t } = useTranslation()
  const [form]: FormInstance<FormValues>[] = Form.useForm()
  const [units, setUnits] = useState<Unit[]>([])
  const [supplierEmails, setSupplierEmails] = useState<SelectOptionsProperties[]>([])
  const [territoryOptions, setTerritoryOptions] = useState<CascaderNode[]>([])
  const [formValues, setFormValues] = useState<FormValues>()
  const [activityDataSources, setActivityDataSources] = useState<ActivityDataSource[]>([])
  const [supplierModalVisible, setSupplierModalVisible] = useState<boolean>(false)
  const [addProductModalVisible, setAddProductModalVisible] = useState<boolean>(false)
  const {
    getOrganizationSuppliers,
    organizationSuppliers,
    getLifecycleSteps,
    lifecycleSteps,
    createSupplier,
  } = useAppContext()
  const {
    getActivityDataSourcesWithoutStructure,
    getDenominatorFromEf,
    units: unitsFromApi,
  } = useLogContext()
  const { territories } = useTerritories()
  const featuresAllowed = useAppSelector(getFeaturesAllowed)

  useEffect(() => {
    if (!organizationSuppliers.length) {
      getOrganizationSuppliers()
    }
    if (!lifecycleSteps.length) {
      getLifecycleSteps([
        LifecycleStepsKey.PRODUCTION,
        LifecycleStepsKey.PACKAGING,
        LifecycleStepsKey.RAW_MATERIALS_ACQUISITION,
        LifecycleStepsKey.DISTRIBUTION_AND_STORAGE,
      ])
    }
    fetchActivityDataSources()
  }, [])

  useEffect(() => {
    if (unitsFromApi) {
      setUnits(unitsFromApi)
    }
  }, [unitsFromApi])

  useEffect(() => {
    if (initialValues && organizationSuppliers) {
      form.setFields(initialValues)
      const supplierData = initialValues.find((el) => el.name === 'supplierId')
      const adsData = initialValues.find((el) => el.name === 'activityDataSourceId')
      if (supplierData) {
        createSupplierEmail(supplierData.value as number)
      }
      if (adsData && Array.isArray(adsData.value)) {
        fetchUnits(adsData.value[adsData.value.length - 1])
      }
    }
  }, [initialValues, organizationSuppliers])

  useEffect(() => {
    if (territories) {
      const continents = territories
        .filter((territory) => territory.continent?.alpha3Code === 'GLO')
        .map((territory) => {
          return { value: territory.id, label: territory.name, children: [] }
        })
      const possibleTerritories = territories
        .filter(
          (territory) =>
            !!territory.continent?.alpha3Code && territory.continent.alpha3Code !== 'GLO',
        )
        .reduce((sorted, next) => {
          const continent = continents.findIndex(
            (continent) => continent.value === next.continentId,
          )
          if (continent > -1) {
            sorted[continent].children.push({ value: next.id, label: next.name, children: [] })
          }
          return sorted
        }, continents as CascaderNode[])
      setTerritoryOptions(possibleTerritories)
    }
  }, [territories])

  useEffect(() => {
    if (formValues) {
      const areMandatoryFieldsTouched = REQUIRED_FIELDS.every(
        (field) =>
          form.isFieldTouched(field) || initialValues.find((el) => el.name === field && el.value),
      )
      const isValid =
        areMandatoryFieldsTouched &&
        Object.entries(formValues)
          .filter(([key]) => REQUIRED_FIELDS.find((field) => key === field))
          .every(([key, value]) => value !== undefined && value !== '' && value !== null)
      setIsFormValid(isValid)
      getFormValues(formValues)
    }
  }, [JSON.stringify(formValues)])

  const supplierOptions = useCallback((): SelectOptionsProperties[] => {
    if (!organizationSuppliers) {
      return []
    }
    return organizationSuppliers.map(({ id: value, name: label }) => ({ value, label }))
  }, [organizationSuppliers])

  const lifecycleStepsOptions = useCallback((): SelectOptionsProperties[] => {
    if (!lifecycleSteps) {
      return []
    }
    return lifecycleSteps.map(({ id: value, name }) => ({ value, label: name as string }))
  }, [lifecycleSteps])

  const unitsOptions = useCallback((): SelectOptionsProperties[] => {
    if (!units) {
      return []
    }
    return units.map(({ id: value, name }) => ({ value, label: name as string }))
  }, [units])

  const fetchUnits = (adsId: number): void => {
    getDenominatorFromEf(adsId)
  }

  const fetchActivityDataSources = async (): Promise<void> => {
    const ads = await getActivityDataSourcesWithoutStructure()
    if (ads) {
      setActivityDataSources(ads)
    }
  }

  const debouncedFetchUnits = useCallback(_.debounce(fetchUnits, 500), [])

  const createSupplierEmail = (supplierId: number): void => {
    const supplier = organizationSuppliers.find(({ id }) => supplierId === id)
    if (supplier) {
      const supplierEmails = supplier.contact.map((contact: { email: string }) => ({
        value: contact.email,
        label: contact.email,
      }))
      setSupplierEmails(supplierEmails)
    }
  }

  const valueChanges = (changedValues: Partial<FormValues>): void => {
    const { supplierId, activityDataSourceId } = changedValues
    if (supplierId) {
      const supplier = organizationSuppliers.find(({ id }) => supplierId === id)
      if (supplier) {
        const supplierEmails = supplier.contact.map((contact: { email: string }) => ({
          value: contact.email,
          label: contact.email,
        }))
        setSupplierEmails(supplierEmails)
      }
      form.resetFields(['email'])
    }
    if (activityDataSourceId && activityDataSourceId.length) {
      debouncedFetchUnits(activityDataSourceId.at(-1) as number)
      form.resetFields(['denominatorUnitId'])
      if (
        formValues?.activityDataSourceId &&
        !_.isEqual(activityDataSourceId, formValues?.activityDataSourceId)
      ) {
        setFormValues(form.getFieldsValue())
      }
    } else {
      setFormValues(form.getFieldsValue())
    }
  }

  const addSupplier = async (supplier: Supplier): Promise<void> => {
    setSupplierModalVisible(false)
    const fetchedSupplier = await createSupplier(supplier)
    if (fetchedSupplier) {
      await getOrganizationSuppliers()
      createSupplierEmail(fetchedSupplier.id)
      const newSupplierEmails = fetchedSupplier.contact.map((contact: { email: string }) => ({
        value: contact.email,
        label: contact.email,
      }))
      setSupplierEmails(newSupplierEmails)
      // Update the form value with the newly created supplier
      form.setFieldsValue({ supplierId: fetchedSupplier.id })
    }
  }

  const updateActivityDataSourcesObject = (
    activityDataSources: ActivityDataSourceWithChildren[],
    newActivityDataSource: ActivityDataSourceWithChildren,
  ): ActivityDataSourceWithChildren[] => {
    const parentId = newActivityDataSource.parentId
    for (const activityDataSource of activityDataSources) {
      if (activityDataSource.id === parentId) {
        activityDataSource.children = [
          ...(activityDataSource?.children ?? []),
          newActivityDataSource,
        ]
        return activityDataSources
      } else if (activityDataSource.children && activityDataSource.children.length) {
        updateActivityDataSourcesObject(activityDataSource.children, newActivityDataSource)
      }
    }
    return activityDataSources
  }

  return (
    <Col span={24}>
      <Form
        category={AnalyticsCategories.FACTOR_REQUESTS}
        layout="vertical"
        initialValues={initialValues}
        form={form}
        onValuesChange={valueChanges}
      >
        <SupplierDetails
          supplierOptions={supplierOptions()}
          supplierEmailOptions={supplierEmails}
          onClickAddSupplier={() => setSupplierModalVisible(true)}
        />
        <RequestDetails
          lifecycleOptions={lifecycleStepsOptions()}
          territoryOptions={territoryOptions}
          activityDataSources={activityDataSources}
          unitsOptions={unitsOptions()}
          onClickAddProduct={() => {
            // Removed due to feature issues check - https://www.notion.so/cozero/Supplier-engagement-feature-Creating-data-for-the-wrong-organization-d9dda6631eb847b9a7ff3c39604c72dc
            // setAddProductModalVisible(true)
          }}
        />
        <SupplierExtraInformation />
      </Form>
      <SuppliersModal
        createSupplier={addSupplier}
        isVisible={supplierModalVisible}
        onClose={() => {
          setSupplierModalVisible(false)
        }}
        featuresAllowed={featuresAllowed}
      />
      <AddProductModal
        isVisible={addProductModalVisible}
        onClose={(activityDataSource?: ActivityDataSourceWithChildren) => {
          if (activityDataSource) {
            setActivityDataSources((prev) =>
              _.cloneDeep(updateActivityDataSourcesObject(prev, activityDataSource)),
            )
          }
          setAddProductModalVisible(false)
        }}
      />
    </Col>
  )
}

export default FactorRequestForm
