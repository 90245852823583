import React, { useContext, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  ActivityDataSource,
  BusinessUnit,
  EmissionFactorChangelog,
  EmissionFactorWithIncludes,
  FactorOrigin,
  FactorRequest,
  FactorRequestResponse,
  Location,
  Page,
  PageFilter,
  Product,
  Territory,
  Unit,
  User,
} from '@cozero/models'
import { LogSorter } from '@cozero/models'

import { ParsedFormValues } from '@/organisms/FactorRequestForm/types'
import { FormValues } from '@/organisms/FactorRequestResponseForm/types'

import useFactorFilterHandler from '../hooks/useFactorWithSearch'
import { SearchFilters } from '../types/general'

export interface FactorFilterContextInterface {
  createCustomFactor: (
    factorId: number,
    body: Partial<EmissionFactorWithIncludes>,
  ) => Promise<EmissionFactorWithIncludes | void>
  updateCustomFactor: (
    factorId: number,
    body: Partial<EmissionFactorWithIncludes>,
  ) => Promise<EmissionFactorWithIncludes | void>
  deleteCustomFactor: (factorId: number) => Promise<void>
  getFactors: (query: {
    logId?: number
    subcategoryId?: number
    activityDataSourceId?: number
    used?: boolean
    custom?: true
    type?: 'product' | 'location'
    logEntryId?: number
    page: number
    pageSize: number
    filters?: PageFilter[]
  }) => Promise<Page<EmissionFactorWithIncludes> | void>
  getFactorsData: () => Promise<{
    products: Product[]
    locations: Location[]
    businessUnits: BusinessUnit[]
  }>
  createFactorRequest: (
    factorRequest: ParsedFormValues,
    businessUnitId: number,
  ) => Promise<FactorRequest & { responsibleUser: User }>
  createFactorRequestResponse: (factorRequestResponse: FormValues) => Promise<FactorRequestResponse>
  getFactorRequests: (businessUnitId: number) => Promise<FactorRequest[]>
  getFactorRequestsAsSupplier: () => Promise<FactorRequest[]>
  factorRequestsAsSupplier: FactorRequest[]
  factorRequests: FactorRequest[]
  saveFactorRequest: (
    id: number,
    factorRequest: Partial<FactorRequest>,
  ) => Promise<FactorRequest | void>
  applyFactorChanges: (emissionFactorsToUpdate: Partial<EmissionFactorChangelog>[]) => Promise<void>
  getFactorChangelogs: (
    page: number,
    pageSize: number,
  ) => Promise<{ docs: EmissionFactorChangelog[]; totalRecords: number }>
  getFactorFilters: () => Promise<{
    factorOrigins: FactorOrigin[]
    units: Unit[]
    activityDataSources: ActivityDataSource[]
    territories: Territory[]
  }>
  filters: PageFilter[]
  sorters: LogSorter[]
  pageNumber: number
  saveFilters: (filters: PageFilter[]) => void
  saveSorters: (sorting: LogSorter[]) => void
  savePageNumber: (currentPage: number) => void
}

const encodeFilters = (data: SearchFilters): string => {
  try {
    return window.btoa(encodeURIComponent(JSON.stringify(data)))
  } catch (error) {
    return JSON.stringify({})
  }
}

const decodeFilters = (str: string): SearchFilters => {
  try {
    return JSON.parse(decodeURIComponent(window.atob(str)))
  } catch (error) {
    return {
      pageNumber: 1,
    }
  }
}

export const factorFilterContext = React.createContext<FactorFilterContextInterface | undefined>(
  undefined,
)

const { Provider } = factorFilterContext

export function useFactorFilterContext(): FactorFilterContextInterface {
  const contextValue = useContext(factorFilterContext)
  if (contextValue === undefined) {
    throw new Error('Context must be inside a Provider')
  }
  return contextValue
}

interface ProviderProps {
  children: React.ReactNode
  saveQueryString?: boolean
}

const FactorFilterProvider: React.FC<ProviderProps> = ({
  children,
  saveQueryString = true,
}: ProviderProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const previousSearchStr = searchParams.get('search')
  const previousSearch = previousSearchStr ? decodeFilters(previousSearchStr) : { pageNumber: 1 }

  const factorAcessors = useFactorFilterHandler({
    ...previousSearch,
  })

  const { filters, sorters, pageNumber } = factorAcessors

  const data: SearchFilters = {
    filters,
    sorters,
    pageNumber,
  }

  useEffect(() => {
    if (saveQueryString) {
      const previousQuery: { [key: string]: string } = {}
      Array.from(searchParams.entries()).forEach(([key, value]) => {
        previousQuery[key] = value
      })
      setSearchParams({ ...previousQuery, search: encodeFilters(data) }, { replace: true })
    }
  }, [JSON.stringify(data)])

  return <Provider value={factorAcessors}>{children}</Provider>
}

export default FactorFilterProvider
