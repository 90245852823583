/* eslint-disable */
import React from 'react'
import { message } from 'antd/es'
import { DownloadOutlined } from '@ant-design/icons'
import Button from '@/atoms/Button'
import * as XLSX from 'xlsx'
import { useTranslation } from 'react-i18next'
import { transformComputedReportKey } from '@/utils/formats'

// Define the interface for formatting strategies
interface FormattingStrategy {
  formatWorkbook: (data: Record<string, any>[], workbook: XLSX.WorkBook, strategyKey: string) => void;
  getFileName: (baseName: string, strategyKey: string) => string;
}

const csrdStrategy: FormattingStrategy = {
  formatWorkbook: (data: Record<string, any>[], workbook: XLSX.WorkBook, strategyKey: string) => {
    const worksheet = XLSX.utils.aoa_to_sheet([[]]);
    
    // Sort data first by scope (groupValue), then by id
    const sortedData = [...data].sort((a, b) => {
      const groupValueA = a.groupValue ?? '';
      const groupValueB = b.groupValue ?? '';

      if (groupValueA !== groupValueB) {
        return groupValueA.localeCompare(groupValueB);
      }
      return a.id - b.id;
    });

    // Extract unique column keys and headers
    const columns = Array.from(new Set(sortedData.map(item => item.columnKey)))
      .map(key => ({
        key,
        header: sortedData.find(item => item.columnKey === key)?.columnHeader || key
      }));

    // Add header row
    XLSX.utils.sheet_add_aoa(worksheet, [['', ...columns.map(col => col.header)]], { origin: 'A1' });

    // Group data by rowKey
    const groupedData = sortedData.reduce((acc, item) => {
      if (!acc[item.rowKey]) {
        acc[item.rowKey] = { 
          header: item.rowHeader, 
          items: {}, 
          groupValue: item.groupValue,
          id: item.id
        };
      }
      acc[item.rowKey].items[item.columnKey] = item.valueType === 'NUMBER' ? 
        (item.numberValue !== null ? item.numberValue : "not provided") : 
        item.stringValue;
      return acc;
    }, {} as Record<string, { header: string, items: Record<string, any>, groupValue: string | null, id: number }>);

    // Add data rows
    let rowIndex = 2;  // Start from row 2 (after header)
    let currentGroup: string | null = null;

    Object.entries(groupedData)
      .sort(([, a], [, b]) => a.id - b.id)  // Sort by id within groups
      .forEach(([rowKey, { header, items, groupValue }]) => {
        // Add group header if it's a new group
        if (groupValue !== currentGroup) {
          currentGroup = groupValue;
          if (groupValue !== null) {
            XLSX.utils.sheet_add_aoa(worksheet, [[groupValue]], { origin: `A${rowIndex}` });
            // Style group header
            for (let col = 0; col <= columns.length; col++) {
              const cell = worksheet[XLSX.utils.encode_cell({ r: rowIndex - 1, c: col })];
              if (cell) cell.s = { 
                fill: { fgColor: { rgb: 'FFFFFF' } },
                font: { bold: true }
              };
            }
            rowIndex++;
          }
        }

        // Add data row
        const rowData = [header];
        columns.forEach(col => {
          rowData.push(items[col.key] !== undefined ? items[col.key] : '');
        });

        XLSX.utils.sheet_add_aoa(worksheet, [rowData], { origin: `A${rowIndex}` });

        rowIndex++;
      });

    // Style header row
    for (let col = 0; col <= columns.length; col++) {
      const cell = worksheet[XLSX.utils.encode_cell({ r: 0, c: col })];
      if (cell) cell.s = { font: { bold: true }, alignment: { horizontal: 'center' } };
    }

    // Auto-size columns
    const maxColWidths = columns.map(col => col.header.length);
    Object.values(groupedData).forEach(({ header, items }) => {
      maxColWidths[0] = Math.max(maxColWidths[0], header.length);
      columns.forEach((col, index) => {
        const value = items[col.key]?.toString() || '';
        maxColWidths[index + 1] = Math.max(maxColWidths[index + 1], value.length);
      });
    });
    worksheet['!cols'] = maxColWidths.map(width => ({ wch: width + 2 }));  // Add some padding

    XLSX.utils.book_append_sheet(workbook, worksheet, `${transformComputedReportKey(strategyKey)} Report`);
  },
  getFileName: (baseName: string, strategyKey: string) => `${baseName}_${strategyKey?.replace(/-/g, "_")}_report`,
};

const formattingStrategies: Record<string, FormattingStrategy> = {
  'csrd-ghg-e1-6': csrdStrategy,
  'cdp-disclosure-2024': csrdStrategy
};

interface ComputedReportsExcelDownloadButtonProps {
  jsonData: Record<string, any>[] | undefined;
  fileName?: string;
  disabled?: boolean;
  strategyKey?: string;
}

const ComputedReportsExcelDownloadButton: React.FC<ComputedReportsExcelDownloadButtonProps> = ({
  jsonData,
  fileName = 'report',
  disabled = false,
  strategyKey = 'csrd-ghg-e1-6',
}) => {
  const { t } = useTranslation('common')
  const strategy = formattingStrategies[strategyKey];

  const convertJsonToExcel = (data: Record<string, any>[]): XLSX.WorkBook => {
    if (!data || data.length === 0) {
      throw new Error('Input data is empty or undefined');
    }

    const workbook = XLSX.utils.book_new();
    strategy.formatWorkbook(data, workbook, strategyKey);

    return workbook;
  };

  const downloadExcel = (): void => {
    if (disabled || !jsonData) {
      return;
    }
    try {
      const workbook = convertJsonToExcel(jsonData);
      const fullFileName = strategy.getFileName(fileName, strategyKey);
      XLSX.writeFile(workbook, `${fullFileName}.xlsx`);
    } catch (error) {
      if (error instanceof Error) {
        console.error(error)
        message.error(t('computed-reports.excel.error.generic'));
      }
    }
  };

  return (
    <Button
      onClick={downloadExcel}
      size="md"
      prefixIcon={<DownloadOutlined />}
      action={`download-${strategyKey}-excel`}
      analyticsProps={{ fileName, strategyKey }}
      disabled={disabled || !jsonData}
    >
      {t('computed-reports.form.download-report-button')}
    </Button>
  );
};

export default ComputedReportsExcelDownloadButton