import { CreateOrganizationDto, UpdateOrganizationDto } from '@cozero/dtos'
import { Organization, User } from '@cozero/models'
import { centralApiGatewayClient } from '@cozero/uris'

import { LIST } from '@/redux/types'
import { providesList } from '@/utils/redux'

import apiSlice from '../api'

import { TAG_ORGANIZATIONS, TAG_ORGANIZATION_USERS } from './tags'

export const GET_ORGANIZATION = 'getOrganization'
export const GET_ORGANIZATION_USERS = 'getOrganizationUsers'
export const GET_ORGANIZATION_USERS_FILTEREDBY_BUSINESSUNIT =
  'getOrganizationUsersFilteredByBusinessUnit'
export const UPDATE_ORGANIZATION = 'updateOrganization'
export const CREATE_ORGANIZATION = 'createOrganization'

const organizationsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [GET_ORGANIZATION]: builder.query<Organization, void>({
      query: () => ({
        url: centralApiGatewayClient.organizations.GET_ME,
        method: 'GET',
      }),
      providesTags: (result) => [{ type: TAG_ORGANIZATIONS, result }],
    }),
    [GET_ORGANIZATION_USERS]: builder.query<
      User[] | undefined,
      { businessUnitId?: number; search?: string }
    >({
      query: ({ businessUnitId, search }) => ({
        url: centralApiGatewayClient.users.SEARCH,
        method: 'GET',
        params: {
          businessUnitId,
          search,
        },
      }),
      providesTags: (result) =>
        providesList<User[], typeof TAG_ORGANIZATION_USERS>(result, TAG_ORGANIZATION_USERS),
    }),

    [GET_ORGANIZATION_USERS_FILTEREDBY_BUSINESSUNIT]: builder.query<
      User[],
      {
        businessUnitId?: number
        search?: string
      }
    >({
      query: ({ businessUnitId, search }) => ({
        url: centralApiGatewayClient.users.SEARCH_FILTER_BY_BU,
        method: 'POST',
        params: {
          businessUnitId,
          search,
        },
      }),
      providesTags: (result) =>
        providesList<User[], typeof TAG_ORGANIZATIONS>(result, TAG_ORGANIZATIONS),
    }),

    [UPDATE_ORGANIZATION]: builder.mutation<Organization, UpdateOrganizationDto>({
      query: (data) => ({
        url: centralApiGatewayClient.organizations.UPDATE_ONE,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (result) => [{ type: TAG_ORGANIZATIONS, id: result?.id }],
      extraOptions: { maxRetries: 0 },
    }),
    [CREATE_ORGANIZATION]: builder.mutation<Organization, Omit<CreateOrganizationDto, 'userId'>>({
      query: (data) => ({
        url: centralApiGatewayClient.organizations.CREATE,
        method: 'POST',
        data,
      }),
      invalidatesTags: [{ type: TAG_ORGANIZATIONS, id: LIST }],
      extraOptions: { maxRetries: 0 },
    }),
  }),
})

export const {
  useGetOrganizationUsersQuery,
  useLazyGetOrganizationUsersQuery,
  useGetOrganizationUsersFilteredByBusinessUnitQuery,
  useLazyGetOrganizationUsersFilteredByBusinessUnitQuery,
  useCreateOrganizationMutation,
  useGetOrganizationQuery,
  useLazyGetOrganizationQuery,
  useUpdateOrganizationMutation,
  usePrefetch,
} = organizationsApiSlice

export default organizationsApiSlice
