import React, { ReactElement, memo, useId, useMemo } from 'react'
import { HiChevronDown } from 'react-icons/hi'

import { Spin } from 'antd/es'
import { Select as AntSelect } from 'antd/es'
import { OptionProps } from 'antd/es/select'

import XMarkIcon from '@heroicons/react/20/solid/XMarkIcon'
import classnames from 'classnames'
import { union } from 'lodash-es'

import { CINDER_BLUE_60 } from '@/styles/variables'

import Checkbox from '../Checkbox'
import Label from '../Label'
import Text from '../Text'
import Tooltip from '../Tooltip'
import WikiIcon from '../WikiIcon'

import { SelectOptions, SelectOptionsProperties, SelectProps } from './Select'
import classes from './Select.module.less'

export const Option = ({ children, ...rest }: OptionProps): ReactElement => (
  <AntSelect.Option {...rest}>{children}</AntSelect.Option>
)

const Select = ({
  children,
  options,
  checkboxedOptions,
  form,
  formItemName,
  size = 'middle',
  mode,
  variant,
  disabled,
  suffixIcon = <HiChevronDown size={15} color={CINDER_BLUE_60} />,
  label,
  dropdownClassName,
  defaultValue,
  responsive,
  ...rest
}: SelectProps): ReactElement => {
  const internalId = useId()

  const LabelComponent = useMemo(() => {
    const htmlFor = rest.id ? rest.id : formItemName ? formItemName : internalId

    if (label && typeof label === 'string') {
      return <Label htmlFor={htmlFor}>{label}</Label>
    } else if (label && typeof label === 'object') {
      return (
        <Label htmlFor={htmlFor}>
          {label.text}
          <WikiIcon title={label.tooltipText} url={label.url} />
        </Label>
      )
    }
    return null
  }, [label])

  const CheckboxedOptions = useMemo(() => {
    if (checkboxedOptions && Array.isArray(checkboxedOptions)) {
      return checkboxedOptions?.map(({ properties, withParentCheckbox }) => (
        <>
          {withParentCheckbox && form && formItemName && (
            <AntSelect.Option
              disabled={withParentCheckbox.disabled}
              key={(withParentCheckbox.label as string).toLocaleLowerCase()}
              value={(withParentCheckbox.label as string).toLocaleLowerCase()}
            >
              <Checkbox
                onChange={(e) => {
                  if (e.target.checked === true) {
                    form.setFieldsValue({
                      [formItemName]: union(
                        properties.map((option) => option),
                        form.getFieldValue(formItemName),
                      ),
                    })
                  } else {
                    form.setFieldsValue({
                      [formItemName]: form.getFieldValue(formItemName).filter(
                        (el: SelectOptionsProperties) =>
                          !properties
                            .map((option) => option)
                            .map(({ value }) => value)
                            .includes(el.value),
                      ),
                    })
                  }
                }}
              >
                {withParentCheckbox.label}
              </Checkbox>
            </AntSelect.Option>
          )}
          {properties?.map(({ label, value, key, type, className, children }) => {
            return (
              <AntSelect.Option
                value={value}
                key={key}
                type={type}
                className={className}
                label={label}
              >
                <>
                  {label}
                  {children}
                </>
              </AntSelect.Option>
            )
          })}
        </>
      ))
    }
    return children
  }, [options, checkboxedOptions])

  const BorderlessVariant = useMemo(() => {
    return options?.map((option) => {
      let content = (
        <div className={classes.optionContainer}>
          {option.icon && <div className={classes.icon}>{option.icon}</div>}
          <div className={classes.labelWrapper}>
            <Text size="xl" fontWeight="medium" className={classes.label}>
              {option.label}
            </Text>
          </div>
        </div>
      )
      if (option?.tooltip) {
        content = <Tooltip {...option.tooltip}>{content}</Tooltip>
      }

      return (
        <Option
          key={option.key}
          value={option.value}
          label={option.label}
          disabled={option.disabled}
        >
          {content}
        </Option>
      )
    })
  }, [options])

  return (
    <div
      className={`${classes.select} ${rest?.className ?? ''} ${variant && classes[variant]} ${
        mode === 'multiple' && classes.multiple
      } ${mode === 'tags' && classes.tags}`}
    >
      {LabelComponent}
      <AntSelect
        removeIcon={<XMarkIcon width={18} height={18} />}
        bordered={variant !== 'borderless'}
        popupClassName={
          variant === 'borderless'
            ? classnames(classes.dropdown, dropdownClassName)
            : dropdownClassName
        }
        dropdownRender={(menu) =>
          !options && !children ? <Spin></Spin> : <div data-cy="atom-select">{menu}</div>
        }
        options={options && !variant ? options : undefined}
        disabled={disabled}
        mode={mode}
        suffixIcon={suffixIcon}
        size={size}
        className={variant}
        defaultValue={defaultValue}
        value={rest.value}
        maxTagCount={responsive ? 'responsive' : undefined}
        maxTagPlaceholder={
          responsive
            ? (omittedValues) => (
                <Tooltip title={omittedValues.map(({ label }) => label).join('; ')}>
                  <span>+ {omittedValues.length} ...</span>
                </Tooltip>
              )
            : undefined
        }
        {...rest}
      >
        <>
          {variant === 'borderless' && options ? BorderlessVariant : null}
          {checkboxedOptions ? CheckboxedOptions : null}
          {children}
        </>
      </AntSelect>
    </div>
  )
}

export type { SelectProps, SelectOptions, SelectOptionsProperties }
export default memo(Select)
