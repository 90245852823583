import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { HiSelector } from 'react-icons/hi'
import { useDispatch } from 'react-redux'

import { TreeSelect } from 'antd/es'

import classNames from 'classnames'

import { BusinessUnit } from '@cozero/models'

import { useAppSelector } from '@/redux'
import { selectUser } from '@/redux/auth'
import {
  selectSelectedBusinessUnit,
  setSelectedBusinessUnit,
  useGetUserBusinessUnitsForestQuery,
  useLazyGetBusinessUnitQuery,
} from '@/redux/businessUnits'

import classes from './classes.module.less'

interface BusinessUnitTreeDropdownProps {
  onChange?: (businessUnit: number) => void
  dark?: boolean
  withDefaultValue?: boolean
  local?: boolean
  value?: number
}

export const BusinessUnitTreeDropdown: React.FC<BusinessUnitTreeDropdownProps> = ({
  onChange,
  dark = false,
  withDefaultValue = true,
  local = false,
  value,
}) => {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const [getBusinessUnit] = useLazyGetBusinessUnitQuery()
  const { data: treeData } = useGetUserBusinessUnitsForestQuery()

  const user = useAppSelector(selectUser)
  const selectedBusinessUnit = useAppSelector(selectSelectedBusinessUnit)

  const fieldValue = withDefaultValue ? selectedBusinessUnit?.id : value

  const onBusinessUnitSelect = useCallback(
    async (businessUnitId: number): Promise<void> => {
      const newBusinessUnit = await getBusinessUnit({
        id: `${businessUnitId}`,
        active: true,
      }).unwrap()

      if (!local) {
        dispatch(setSelectedBusinessUnit(newBusinessUnit))
      }

      if (onChange) {
        onChange(newBusinessUnit.id)
      }
    },
    [onChange],
  )

  useEffect(() => {
    if (!selectedBusinessUnit && user && user.businessUnit) {
      dispatch(setSelectedBusinessUnit(user?.businessUnit as BusinessUnit))
    }
  }, [user])

  if (!treeData || !selectedBusinessUnit) {
    return null
  }

  return (
    <TreeSelect<number>
      virtual
      placeholder={t('business-unit.select')}
      onSelect={onBusinessUnitSelect}
      defaultValue={fieldValue}
      treeDefaultExpandedKeys={selectedBusinessUnit?.ancestorIds}
      value={fieldValue}
      treeData={treeData}
      showSearch
      treeNodeLabelProp="title"
      treeNodeFilterProp="title"
      suffixIcon={<HiSelector />}
      popupClassName={classes.dropdown}
      className={classNames(classes.treeSelect, { [classes.white]: !dark })}
      dropdownMatchSelectWidth={false}
      treeLine
    />
  )
}
