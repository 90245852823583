import React from 'react'
import { useTranslation } from 'react-i18next'

import { Spin } from 'antd/es'

import { debounce } from 'lodash-es'

import Select, { Option, SelectProps } from '@/atoms/Select'

import classes from './FiltersDrawer.module.less'

type Props = SelectProps & {
  multipleAllowed?: boolean
  onSearch?: (value: string) => void
}

const SearchSelectComponent = ({
  multipleAllowed,
  value,
  onChange,
  onSearch,
  loading,
  options,
  ...props
}: Props): React.ReactElement => {
  const { t } = useTranslation('common')

  return (
    <Select
      {...props}
      showSearch
      mode={multipleAllowed ? 'multiple' : undefined}
      placeholder={props.placeholder || t('log.filter.search')}
      value={value}
      onChange={(value, option) => {
        const cleanValue = multipleAllowed ? value : [value]

        onChange?.(cleanValue, option)
      }}
      onSearch={debounce((term) => onSearch?.(term), 500)}
      dropdownRender={(menu) => {
        if (loading) {
          return (
            <span className={classes.dropdownContainer}>
              <Spin />
            </span>
          )
        }
        return options?.length ? (
          menu
        ) : (
          <span className={classes.dropdownContainer}>{t('log.filter.search')}</span>
        )
      }}
    >
      {options?.map((option) => (
        <Option key={option.value} value={option.value} label={option.label}>
          {option.label}
        </Option>
      ))}
    </Select>
  )
}

export const SearchSelect = React.memo(SearchSelectComponent)
