/* eslint react-hooks/exhaustive-deps: 2 */
import React from 'react'
import { useNavigate } from 'react-router'

import { Col, Row } from 'antd'

import moment, { Moment } from 'moment'

import { routes } from '@cozero/utils'

import Table from '@/molecules/Table'
import { TableHeaderCellPopover } from '@/molecules/Table/TableHeaderCellPopover/TableHeaderCellPopover'

import Card from '@/atoms/Card'
import DateRangePicker from '@/atoms/DateRangePicker'

import { useGetSearchLogEntriesQuery } from '@/redux/logEntries'

import classes from './Log.module.less'
import { useFilters } from './hooks/useFilters'
import { usePagination } from './hooks/usePagination'
import { useSorting } from './hooks/useSorting'
import { useTableColumns } from './hooks/useTableColumns'

export const LogEntries = (): JSX.Element => {
  const { columns } = useTableColumns()
  const { currentSorting, setSorting } = useSorting()
  const { onChange, ...pagination } = usePagination()
  const { dateFilter, setDateFilter } = useFilters()
  const navigate = useNavigate()

  const { data: { total, data: logEntries = [] } = {}, isFetching } = useGetSearchLogEntriesQuery(
    {
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
      filters: {
        startDate: dateFilter?.[0],
        endDate: dateFilter?.[1],
      },
      ...(!!currentSorting && {
        sortingKey: currentSorting.key,
        sortingDirection: currentSorting.direction,
      }),
    },
    {
      skip: !pagination.current || !pagination.pageSize,
    },
  )

  const columnsWithPopover = columns.map((column, index) => {
    return {
      ...column,
      key: `org-emissions-col-${index}`,
      title: (
        <TableHeaderCellPopover
          title={String(column.title)}
          sortingKey={column?.sortingKey}
          sorting={currentSorting}
          setSorting={setSorting}
        />
      ),
      onHeaderCell: () => ({
        className: classes.cellHeader,
      }),
    }
  })

  const onOpenChange = React.useCallback(
    (value: [Moment | null, Moment | null] | null) => {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

      if (!value?.[0] || !value?.[1]) {
        setDateFilter(undefined)
        return
      }

      const startOfMonth = moment(value[0].tz(timezone).startOf('month').utc().toDate())
      const endOfMonth = moment(value[1]?.tz(timezone).endOf('month').utc().toDate())

      setDateFilter([startOfMonth.toDate(), endOfMonth.toDate()])
    },
    [setDateFilter],
  )

  const dateRange = React.useMemo<[Moment | null, Moment | null]>(
    () => (dateFilter ? [moment(dateFilter[0]), moment(dateFilter[1])] : [null, null]),
    [dateFilter],
  )

  return (
    <Card shadow="none" data-cy="hotspot-analysis-section" className={classes.logEntriesWrapper}>
      <Row gutter={[16, 16]}>
        <Col flex={1}></Col>
        <Col>
          <DateRangePicker
            disabled={false}
            inputReadOnly
            format="yyyy-MM-DD"
            picker="date"
            style={{ width: 250 }}
            value={dateRange}
            onCloseChange={onOpenChange}
            data-cy="leo-date-picker"
          />
        </Col>
        <Col flex={1}>
          <Table
            loading={isFetching}
            columns={columnsWithPopover}
            dataSource={logEntries}
            scroll={{ x: '100%' }}
            tableLayout="fixed"
            onChange={onChange}
            pagination={{ ...pagination, total }}
            showWrapper={false}
            rowClassName={classes.tableRow}
            rowKey="id"
            onRow={(record) => ({
              onClick: (event) => {
                const logRoute = routes.log.carbonFootprint.organization.edit.replace(
                  ':id',
                  String(record.log.id),
                )
                if (event.metaKey) {
                  window.open(logRoute)
                  return
                }

                navigate(logRoute)
              },
            })}
          />
        </Col>
      </Row>
    </Card>
  )
}
