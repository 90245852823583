import React, {
  ChangeEvent,
  ClipboardEvent,
  KeyboardEvent,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'

import { Col, Popconfirm, Row } from 'antd/es'

import { CloseOutlined } from '@ant-design/icons'
import { DeleteOutlined } from '@ant-design/icons'

import InputField from '@/atoms/InputField'
import Select, { Option } from '@/atoms/Select'
import Text from '@/atoms/Text'

import { truncate } from '@/utils/string'

import classes from './TagInput.module.less'

type Tag = string

type TagOption = string

interface Props {
  onChange?: (tags: Tag[]) => void
  onCreateTag?: (customTag: string) => void
  onDeleteTag?: (customTag: string) => void
  tags?: Tag[]
  value?: Tag[]
  options?: TagOption[]
  reservedOptions?: TagOption[]
  loadingOptions?: boolean
  placeholder?: string
}

const SimpleTagInput = ({ tags, onChange }: Omit<Props, 'options'>): JSX.Element => {
  const { t } = useTranslation('common')
  const firstRender = useRef(true)
  const [value, setValue] = useState<Tag[]>([])
  const [inputVal, setInputVal] = useState<string>('')
  const [isKeyReleased, setIsKeyReleased] = useState<boolean>(false)
  const [saveValue, setSaveValue] = useState<boolean>(false)

  const onPaste = (e: ClipboardEvent<HTMLInputElement>): void => {
    e.preventDefault()
    e.stopPropagation()
    const pastedValue = e.clipboardData.getData('Text').trim().split(';')
    setValue([...value, ...pastedValue])
    setInputVal('')
    return setSaveValue(true)
  }
  const triggerOnChange = useCallback(() => {
    if (onChange) {
      onChange(value)
    }
  }, [value])

  const onInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target
    setInputVal(value)
  }

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>): void => {
    const { code } = e
    const saveKeys = ['Enter', 'Comma', ',']
    const deleteKeys = ['Backspace']
    const trimmedInput = inputVal.trim()

    if (saveKeys.includes(code) || deleteKeys.includes(code)) {
      setIsKeyReleased(false)

      if (saveKeys.includes(code) && trimmedInput.length && !value.includes(trimmedInput)) {
        e.preventDefault()
        e.stopPropagation()
        setValue([...value, trimmedInput])
        setInputVal('')
        return setSaveValue(true)
      }

      if (deleteKeys.includes(code) && !inputVal.length && value.length && isKeyReleased) {
        e.preventDefault()
        const tagsCopy = [...value]
        const poppedTag = tagsCopy.pop()

        setValue(tagsCopy)
        return setInputVal(poppedTag ?? '')
      }
    }
  }

  const onKeyUp = (): void => setIsKeyReleased(true)

  const deleteTag = (index: number): void => {
    setValue((prevState) => prevState.filter((_tag: Tag, i) => i !== index))
    return setSaveValue(true)
  }

  useEffect(() => {
    if (saveValue) {
      triggerOnChange()
      setSaveValue(false)
    }
  }, [saveValue])

  useEffect(() => {
    if (tags && firstRender) {
      setValue(tags)
      firstRender.current = false
    }
  }, [tags])

  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        {value.map((tag: Tag, index) => (
          <div key={tag} className={classes.tag}>
            {tag}
            <button onClick={() => deleteTag(index)}>
              <CloseOutlined />
            </button>
          </div>
        ))}
        <InputField
          placeholder={t('actions.tags-placeholder')}
          value={inputVal}
          onBlur={() => {
            const trimmedInput = inputVal.trim()
            if (trimmedInput.length) {
              setValue([...value, trimmedInput])
              setInputVal('')
              setSaveValue(true)
            }
          }}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          onChange={onInputChange}
          onPressEnter={(e) => e.preventDefault()}
          onPaste={onPaste}
          bordered={false}
        />
      </div>
      <Text className={classes.help} color="secondary">
        {t('actions.add-tag')}
      </Text>
    </div>
  )
}

const SelectTagInput = ({
  value,
  tags,
  options,
  reservedOptions,
  loadingOptions,
  onChange,
  onCreateTag,
  onDeleteTag,
  placeholder,
}: Props & { options: TagOption[] }): JSX.Element => {
  const { t } = useTranslation('common')
  const [tagOptions, setTagOptions] = useState<TagOption[]>(options)
  const [customTag, setCustomTag] = useState<string>('')

  const deleteTag = (deletedTag: string): void => {
    setTagOptions(tagOptions.filter((x) => deletedTag !== x))
    onDeleteTag && onDeleteTag(deletedTag)
    setCustomTag('')
  }

  const selectOption = (selectedTag: string[] | string): void => {
    const auxArr = Array.isArray(selectedTag)
      ? [...tagOptions, ...selectedTag]
      : [...tagOptions, selectedTag]
    const tagsSet = new Set(auxArr)

    const tagsArray = Array.from(tagsSet)

    if (tagsArray.length > tagOptions.length) {
      onCreateTag &&
        onCreateTag(Array.isArray(selectedTag) ? selectedTag[0] : (selectedTag as string))
    }

    setTagOptions(tagsArray)
    setCustomTag('')
  }

  useEffect(() => {
    setTagOptions(options)
  }, [options])

  return (
    <div className={classes.container}>
      <Select
        loading={loadingOptions}
        mode="multiple"
        placeholder={placeholder ?? t('actions.tags-placeholder')}
        optionLabelProp="label"
        style={{ width: '100%' }}
        value={value}
        defaultValue={value}
        onDeselect={() => setCustomTag('')}
        onSelect={(val: string) => {
          selectOption(val)
        }}
        onChange={(event: string[]) => {
          onChange && onChange(event as string[])
        }}
        onSearch={(event) => {
          setCustomTag(event)
        }}
      >
        {tagOptions.map((item) => (
          <Option key={item} value={item} label={item}>
            <Row className={classes.optionRow}>
              <Col>{truncate(item, 48)}</Col>
              {!value?.includes(item) && !reservedOptions?.includes(item) && (
                <Col>
                  <Popconfirm
                    overlayStyle={{ zIndex: 99999999 }}
                    placement="bottomRight"
                    title={t('log.tag-deletion-confirm')}
                    onConfirm={(event) => {
                      event?.stopPropagation()
                      event?.preventDefault()
                      deleteTag(item)
                    }}
                  >
                    {onDeleteTag && (
                      <a
                        onClick={(event) => {
                          event?.preventDefault()
                          event.stopPropagation()
                        }}
                        href="#"
                      >
                        <DeleteOutlined className={classes.deleteIcon} />
                      </a>
                    )}
                  </Popconfirm>
                </Col>
              )}
            </Row>
          </Option>
        ))}
        {!!customTag && !tagOptions.includes(customTag) && onCreateTag && (
          <Option key={customTag} value={customTag} label={customTag}>
            <div>{`Add: ${customTag}`}</div>
          </Option>
        )}
      </Select>
    </div>
  )
}

const TagInput = (props: Props): ReactElement => {
  return props.options ? (
    <SelectTagInput reservedOptions={[]} {...(props as Props & { options: TagOption[] })} />
  ) : (
    <SimpleTagInput {...props} />
  )
}

export default TagInput
