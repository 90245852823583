/* eslint react-hooks/exhaustive-deps: 2 */

import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { TablePaginationConfig } from 'antd/es'
import { FilterValue, SorterResult } from 'antd/es/table/interface'
import { ColumnType } from 'antd/lib/table'

import { PushpinFilled, PushpinOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import moment from 'moment'

import {
  HotspotAnalysisData,
  HotspotAnalysisFilterValues,
  HotspotAnalysisHotspotFilter,
  HotspotAnalysisSorting,
  HotspotAnalysisViewType,
} from '@cozero/models'
import { routes } from '@cozero/utils'

import Table from '@/molecules/Table'

import Button from '@/atoms/Button'
import Tag from '@/atoms/Tag'
import Tooltip from '@/atoms/Tooltip'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useMixpanel } from '@/hooks/useMixpanel'
import usePreview from '@/hooks/usePreview'
import { useAppDispatch, useAppSelector } from '@/redux'
import {
  selectHotspotAnalysisDateFilter,
  selectHotspotAnalysisExpandedRowKeys,
  selectHotspotAnalysisFilters,
} from '@/redux/actAnalytics/selectors'
import { setHotspotExpandedRowKeys } from '@/redux/actAnalytics/slice'
import { useGetModeledImpactCategoriesQuery } from '@/redux/categories/api'
import { COZERO_BLUE_80 } from '@/styles/variables'
import { formatCompact, formatPercentCompact } from '@/utils/number'

import { HotspotAnalysisCell } from './HotspotAnalysisCell'
import { HotspotAnalysisReferencePeriodConflictModal } from './HotspotAnalysisReferencePeriodConflictModal'
import classes from './HotspotAnalysisTable.module.less'
import { useHotspotAnalysisContext } from './hooks/useHotspotAnalysisContext'
import { usePinEmissionRows } from './hooks/usePinEmissionRows'
import { useReferencePeriod } from './hooks/useReferencePeriod'
import { HotspotAnalysisRow } from './types/HotSpotAnalysis.types'
import {
  getBusinessActivity,
  getHotspotAssessmentLabel,
  getHotspotAssessmentType,
  getLevelDescriptor,
  getLogCategory,
  getLogSubcategory,
  getParentRowKey,
  getRowKey,
  getRowKeyWithSuffix,
  getScope,
  getScopeCategory,
  getSortedData,
} from './utils/HotspotAnalysis.utils'

interface Props {
  data: HotspotAnalysisRow[]
  pins: HotspotAnalysisData[]
  isLoading: boolean
  viewId: number
}

export type ActionSimulatorQueryParams = {
  hotspotAssessment: HotspotAnalysisData['hotspotAssessment']
  categoryId: HotspotAnalysisData['logCategoryId']
  subCategoryId: HotspotAnalysisData['logEntrySubcategoryId']
  activityDataSourceId: HotspotAnalysisData['activityDataSourceId']
  startDateFilter: string
  endDateFilter: string
  businessUnitIds: HotspotAnalysisFilterValues['businessUnit']
  locationIds: HotspotAnalysisFilterValues['locationId']
  activityDataSourceName: string
  subcategoryName: string
  referencePeriodYear: number
}

const TakeActionButton = ({
  params,
  selectedDateFilter,
}: {
  params: Omit<
    ActionSimulatorQueryParams,
    'startDateFilter' | 'endDateFilter' | 'referencePeriodYear'
  >
  selectedDateFilter: [Date, Date] | undefined
}): ReactElement => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const { trackAction } = useMixpanel()
  const [showConflictModal, setShowConflictModal] = useState(false)
  const { isReferencePeriod, referencePeriod } = useReferencePeriod()
  const { data: modeledImpactCategories = [] } = useGetModeledImpactCategoriesQuery(
    params.subCategoryId as number,
    {
      skip: !params.subCategoryId,
    },
  )

  const url = new URL(routes.act.actionSimulator, window.location.origin)

  const queryParams: ActionSimulatorQueryParams = {
    ...params,
    referencePeriodYear: moment(referencePeriod?.[0]).year() as number,
    startDateFilter: selectedDateFilter?.[0]?.toISOString?.() as string,
    endDateFilter: selectedDateFilter?.[1]?.toISOString?.() as string,
  }
  Object.entries(queryParams).forEach(([key, value]) =>
    url.searchParams.append(key, value as string),
  )

  const onClick = useCallback(() => {
    if (!isReferencePeriod(selectedDateFilter)) {
      trackAction(AnalyticsCategories.ACT_HOTSPOT_ANALYSIS, 'reference-period-modal-triggered', {
        selectedDateFilter,
        referencePeriod,
      })
      setShowConflictModal(true)
      return
    }
    navigate({
      pathname: routes.act.actionSimulator,
      search: url.search,
    })
  }, [selectedDateFilter, referencePeriod, url.search, isReferencePeriod, trackAction, navigate])

  return (
    <>
      <HotspotAnalysisReferencePeriodConflictModal
        referencePeriod={referencePeriod}
        open={showConflictModal}
        onClose={() => setShowConflictModal(false)}
      />
      <Button
        action="take-action-click"
        category={AnalyticsCategories.ACT_ACTION_SIMULATOR}
        analyticsProps={{ isModeledImpactCategory: modeledImpactCategories.length > 0 }}
        onClick={onClick}
      >
        {t('act.analytics.hotspotAnalysis.table.takeActionButton')}
      </Button>
    </>
  )
}

export const HotspotAnalysisTable = ({ data, isLoading, viewId, pins }: Props): ReactElement => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation('common')
  const { trackAction } = useMixpanel()
  const { isPreview } = usePreview()

  const { type } = useHotspotAnalysisContext()
  const isDefaultType = type === HotspotAnalysisViewType.DEFAULT

  const hotspotAnalysisFilters = useAppSelector(selectHotspotAnalysisFilters)
  const selectedDateFilter = useAppSelector(selectHotspotAnalysisDateFilter)

  const expandedRowKeys = useAppSelector(selectHotspotAnalysisExpandedRowKeys)
  useEffect(() => {
    dispatch(setHotspotExpandedRowKeys([]))
  }, [dispatch])

  const { addPinnedRow, removePinnedRow } = usePinEmissionRows({
    viewId,
  })
  const [sorting, setSorting] = useState<HotspotAnalysisSorting | null>(null)

  const handleTableChange = useCallback(
    (
      _pagination: TablePaginationConfig,
      _filters: Record<string, FilterValue | null>,
      rawSorting: SorterResult<HotspotAnalysisRow> | SorterResult<HotspotAnalysisRow>[],
    ): void => {
      if (rawSorting) {
        const sorting = Array.isArray(rawSorting) ? rawSorting[0] : rawSorting
        const field =
          typeof sorting.field !== 'string'
            ? undefined
            : (sorting.field as keyof HotspotAnalysisData)
        let order: 'asc' | 'desc' | undefined

        if (sorting.order) {
          order = sorting.order === 'ascend' ? 'asc' : 'desc'
        }

        setSorting(!field || !order ? null : { field, order })
        trackAction(AnalyticsCategories.ACT_HOTSPOT_ANALYSIS, 'sorting-updated', { field, order })
      }
    },
    [trackAction],
  )
  const rowsPinned = useMemo(
    () => (isDefaultType ? pins.map((pin) => ({ ...pin, pinned: true })) : []),
    [pins, isDefaultType],
  )

  const rows: HotspotAnalysisRow[] = useMemo(
    () => [...getSortedData(rowsPinned, sorting), ...getSortedData(data, sorting)],
    [rowsPinned, data, sorting],
  )

  const pinnedRowsByKey: Record<string, HotspotAnalysisRow> = useMemo(
    () => Object.fromEntries(rowsPinned.map((row) => [getRowKey(row), row])),
    [rowsPinned],
  )

  const isActiveFilters = useMemo(
    () => Object.values(hotspotAnalysisFilters).some((value) => !!value),
    [hotspotAnalysisFilters],
  )

  const emptyTile = useMemo(() => {
    return data.length === 0 && isActiveFilters
      ? t('act.analytics.hotspotAnalysis.table.empty-state.title')
      : undefined
  }, [data, isActiveFilters, t])

  const emptyDescription = useMemo(() => {
    return data.length === 0 && isActiveFilters
      ? t('act.analytics.hotspotAnalysis.table.empty-state.description')
      : undefined
  }, [t, isActiveFilters, data.length])

  return (
    <Table<HotspotAnalysisRow>
      emptyTitle={emptyTile}
      emptyDescription={emptyDescription}
      onChange={handleTableChange}
      className={classes.table}
      pagination={false}
      rowKey={getRowKeyWithSuffix}
      showWrapper={false}
      dataSource={rows}
      scroll={{ x: 'max-content' }}
      loading={isLoading}
      rowClassName={(record) => {
        const rowKey = getRowKey(record)

        const expanded = expandedRowKeys.includes(rowKey)
        const parentExpanded = expandedRowKeys.includes(getParentRowKey(record))

        return classNames({
          [classes.expanded]: expanded,
          [classes.parentExpanded]: parentExpanded,
          [classes.pinned]: !!pinnedRowsByKey[rowKey],
        })
      }}
      data-cy="hotspot-analysis-table"
      expandable={{ showExpandColumn: false, expandedRowKeys }}
      onRow={(record) => ({
        onClick: () => {
          const key = getRowKey(record)

          if (expandedRowKeys.includes(key)) {
            trackAction(AnalyticsCategories.ACT_HOTSPOT_ANALYSIS, 'row-collapsed', {
              level: getLevelDescriptor(record),
            })
            return dispatch(
              setHotspotExpandedRowKeys(
                expandedRowKeys.filter((k) => !(k as string).startsWith(key)),
              ),
            )
          }

          if (!!record.children?.length && record.children?.length > 0) {
            trackAction(AnalyticsCategories.ACT_HOTSPOT_ANALYSIS, 'row-expanded', {
              level: getLevelDescriptor(record),
            })
            return dispatch(setHotspotExpandedRowKeys([...expandedRowKeys, key]))
          }

          return dispatch(setHotspotExpandedRowKeys(expandedRowKeys))
        },
      })}
      columns={[
        {
          title: t<string>('act.analytics.hotspotAnalysis.table.scopeCategoryNumber.label'),
          dataIndex: 'scopeCategoryNumber',
          render: (_value, record) => (
            <HotspotAnalysisCell
              record={record}
              level={1}
              isExpanded={expandedRowKeys.includes(getRowKey(record))}
            >
              {getScope(record)}
            </HotspotAnalysisCell>
          ),
        },
        {
          title: t<string>('act.analytics.hotspotAnalysis.table.scopeSubcategory.label'),
          dataIndex: 'scopeSubcategory',
          render: (_value, record) => (
            <HotspotAnalysisCell
              record={record}
              level={2}
              isExpanded={expandedRowKeys.includes(getRowKey(record))}
            >
              {getScopeCategory(record)}
            </HotspotAnalysisCell>
          ),
        },
        {
          title: t<string>('act.analytics.hotspotAnalysis.table.logCategoryName.label'),
          dataIndex: 'logCategoryName',
          render: (_value, record) => (
            <HotspotAnalysisCell
              record={record}
              level={3}
              isExpanded={expandedRowKeys.includes(getRowKey(record))}
            >
              {getLogCategory(record)}
            </HotspotAnalysisCell>
          ),
        },
        {
          title: t<string>('act.analytics.hotspotAnalysis.table.logEntrySubcategoryName.label'),
          dataIndex: 'logEntrySubcategoryName',
          render: (_value, record) => (
            <HotspotAnalysisCell
              record={record}
              level={4}
              isExpanded={expandedRowKeys.includes(getRowKey(record))}
            >
              {getLogSubcategory(record)}
            </HotspotAnalysisCell>
          ),
        },
        {
          title: t<string>('act.analytics.hotspotAnalysis.table.activityDataSourceName.label'),
          dataIndex: 'activityDataSourceName',
          render: (_value, record) => (
            <HotspotAnalysisCell
              record={record}
              level={5}
              isExpanded={expandedRowKeys.includes(getRowKey(record))}
            >
              {getBusinessActivity(record)}
            </HotspotAnalysisCell>
          ),
        },
        {
          title: t<string>('act.analytics.hotspotAnalysis.table.emissions.label'),
          dataIndex: 'emissions',
          sorter: true,
          align: 'right',
          render(value) {
            return formatCompact(value)
          },
        },
        {
          title: t<string>('act.analytics.hotspotAnalysis.table.emissionsOfScopePercent.label'),
          dataIndex: 'emissionsOfScopePercent',
          key: 'emissionsPerScope',
          sorter: true,
          align: 'right',
          render(value) {
            return formatPercentCompact(value, { limited: true })
          },
        },
        {
          title: t<string>('act.analytics.hotspotAnalysis.table.emissionsOfOrganization.label'),
          dataIndex: 'emissionsOfOrganization',
          key: 'emissionsPerOrg',
          sorter: true,
          align: 'right',
          render(value) {
            return formatPercentCompact(value, { limited: true })
          },
        },
        ...(isDefaultType
          ? ([
              {
                title: t<string>('act.analytics.hotspotAnalysis.table.hotspotAssessment.label'),
                dataIndex: 'hotspotAssessment',
                render: (value) => {
                  if (!value) {
                    return null
                  }

                  const type = getHotspotAssessmentType(value)

                  return (
                    <Tooltip
                      content={t('act.analytics.hotspotAnalysis.table.hotspotAssessment.tooltip')}
                      showArrow
                    >
                      <Tag size="xs" type={type}>
                        {getHotspotAssessmentLabel(value)}
                      </Tag>
                    </Tooltip>
                  )
                },
              },
              {
                fixed: 'right',
                title: t<string>('act.analytics.hotspotAnalysis.table.pinActivity.label'),
                dataIndex: 'pinActivity',
                render: (_value, { children, ...record }) => {
                  if (typeof record.activityDataSourceId !== 'number') {
                    return
                  }

                  const rowKey = getRowKey(record)

                  if (pinnedRowsByKey[rowKey]) {
                    return (
                      <div className={classes.pinColumn}>
                        <PushpinFilled
                          style={{ color: COZERO_BLUE_80, fontSize: 20, paddingTop: 4 }}
                          title={t('act.analytics.hotspotAnalysis.table.pinActivity.unpin')}
                          onClick={(event) => {
                            event.stopPropagation()
                            trackAction(AnalyticsCategories.ACT_HOTSPOT_ANALYSIS, 'row-unpinned')
                            removePinnedRow({
                              pinId: pinnedRowsByKey[rowKey].pinId as number,
                              viewId,
                            })
                          }}
                          data-cy="hotspot-analysis-table-pin-remove"
                        />
                        {isPreview && (
                          <TakeActionButton
                            selectedDateFilter={selectedDateFilter}
                            params={{
                              hotspotAssessment:
                                record.hotspotAssessment as HotspotAnalysisHotspotFilter,
                              categoryId: record.logCategoryId as number,
                              subCategoryId: record.logEntrySubcategoryId as number,
                              activityDataSourceId: record.activityDataSourceId as number,
                              activityDataSourceName: record.activityDataSourceName as string,
                              businessUnitIds: hotspotAnalysisFilters.businessUnit ?? [],
                              locationIds: hotspotAnalysisFilters.locationId ?? [],
                              subcategoryName: record.logEntrySubcategoryName as string,
                            }}
                          />
                        )}
                      </div>
                    )
                  }

                  return (
                    <div className={classes.pinColumn}>
                      <PushpinOutlined
                        style={{ fontSize: 20, paddingTop: 4 }}
                        title={t('act.analytics.hotspotAnalysis.table.pinActivity.pin')}
                        onClick={(event) => {
                          event.stopPropagation()
                          trackAction(AnalyticsCategories.ACT_HOTSPOT_ANALYSIS, 'row-pinned')
                          addPinnedRow({
                            ...record,
                            categoryId: record.logCategoryId as number,
                            subcategoryId: record.logEntrySubcategoryId as number,
                            activityDataSourceId: record.activityDataSourceId as number,
                            viewId,
                          })
                        }}
                        data-cy="hotspot-analysis-table-pin-add"
                      />
                      {isPreview && (
                        <TakeActionButton
                          selectedDateFilter={selectedDateFilter}
                          params={{
                            hotspotAssessment:
                              record.hotspotAssessment as HotspotAnalysisHotspotFilter,
                            categoryId: record.logCategoryId as number,
                            subCategoryId: record.logEntrySubcategoryId as number,
                            activityDataSourceId: record.activityDataSourceId as number,
                            businessUnitIds: hotspotAnalysisFilters.businessUnit ?? [],
                            locationIds: hotspotAnalysisFilters.locationId ?? [],
                            activityDataSourceName: record.activityDataSourceName as string,
                            subcategoryName: record.logEntrySubcategoryName as string,
                          }}
                        />
                      )}
                    </div>
                  )
                },
              },
            ] satisfies ColumnType<HotspotAnalysisRow>[])
          : []),
      ]}
    />
  )
}
