import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { Col, Modal, Row, Spin } from 'antd/es'

import { saveAs } from 'file-saver'
import produce from 'immer'
import { json2csv } from 'json-2-csv'
import { debounce } from 'lodash-es'

import { routes } from '@cozero/utils'

import OnboardingStepModal, { onboardingModalStyle } from '@/molecules/OnboardingStepModal'
import UsersTable from '@/molecules/UsersTable'

import Button from '@/atoms/Button'
import InputField from '@/atoms/InputField'
import Text from '@/atoms/Text'
import Title from '@/atoms/Title'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useSubscriptionContext } from '@/contexts/subscription'
import { useAppDispatch, useAppSelector } from '@/redux'
import {
  getFeaturesAllowed,
  selectUser,
  selectUserOrganization,
  useLazyGetUserDataQuery,
} from '@/redux/auth'
import { setSteps, useUpdateOnboardingMutation } from '@/redux/onboarding'
import { useGetOrganizationUsersQuery } from '@/redux/organizations'
import { useGetUserCountQuery, useLazyExportUsersQuery } from '@/redux/users'
import { slugify } from '@/utils/string'

import classes from './classes.module.less'

const Users = (): ReactElement => {
  const { t } = useTranslation('common')
  const user = useAppSelector(selectUser)
  const featuresAllowed = useAppSelector(getFeaturesAllowed)
  const organization = useAppSelector(selectUserOrganization)
  const dispatch = useAppDispatch()
  const [exportUsers] = useLazyExportUsersQuery()
  const [getMe] = useLazyGetUserDataQuery()
  const { setSubscribeModalSettings, getLimit } = useSubscriptionContext()
  const [searchValue, setSearchValue] = useState<string>('')
  const [loading, setLoading] = useState(false)
  const [updateOnboarding] = useUpdateOnboardingMutation()
  const { data: userCount } = useGetUserCountQuery()
  const { data: users = [], isLoading: loadingUsers } = useGetOrganizationUsersQuery(
    {
      businessUnitId: user?.businessUnit?.id,
      search: searchValue,
    },
    { skip: !user?.businessUnit?.id },
  )
  const navigate = useNavigate()
  const location = useLocation()
  const onSearch = useCallback(
    debounce((value) => {
      setSearchValue(value)
    }, 500),
    [],
  )

  const updatedSteps = useMemo(
    (state = true) => {
      const stepIndex = user?.onboardingSteps.findIndex(
        (step) => step.onboardingStep.key === 'users',
      )
      if (stepIndex === undefined && stepIndex !== -1) {
        throw new Error('Step not found')
      }

      const clonedSteps = user?.onboardingSteps ? [...user.onboardingSteps] : []
      return produce(clonedSteps, (draft) => {
        if (draft && draft[stepIndex]) {
          draft[stepIndex].completed = state
        }
      })
    },
    [user],
  )

  const finishOnboardingStep = async (): Promise<void> => {
    const data = await updateOnboarding({
      steps: updatedSteps,
    }).unwrap()

    if (data) {
      await getMe()
      dispatch(setSteps(data))
      Modal.success({
        content: <OnboardingStepModal stepKey="users" />,
        ...onboardingModalStyle,
      })

      return navigate(location.pathname, { replace: true })
    }
  }

  // On return from adding a new user, check if we finished the onboarding step
  useEffect(() => {
    if ((location.state as { finishOnboarding: boolean })?.finishOnboarding) {
      finishOnboardingStep()
    }
  }, [location.state])

  const goToUserInvitation = async (): Promise<void> => {
    const limit = getLimit(organization, 'users')
    if (limit && userCount && userCount >= limit.max) {
      setSubscribeModalSettings({
        closable: true,
        title: t('subscription.upgrade-modal.title-limit', {
          limit: limit.max,
          item: t('settings.users.title'),
        }),
        visible: true,
      })
      return
    }
    navigate(routes.settings.addUser)
  }

  async function downloadUsers(): Promise<void> {
    setLoading(true)
    const users = await exportUsers().unwrap()
    json2csv(users ?? [], (_error, data) =>
      data
        ? saveAs(
            new Blob([data], {
              type: 'text/csv',
            }),
            `${slugify('Users')}.csv`,
          )
        : undefined,
    )
    setLoading(false)
  }

  return (
    <Row>
      <Col span={24} data-cy="view-users-table">
        <Row className={classes.section}>
          <Col span={24}>
            <Title size="sm">{t('settings.users.title')}</Title>
          </Col>
          <Col span={24}>
            <Text size="xl" color="secondary">
              {t('settings.users.subtitle')}
            </Text>
          </Col>
        </Row>
        <Row align="middle" justify="end" gutter={16} className={classes.section}>
          <Col xs={8} lg={6}>
            <InputField
              type="search"
              placeholder={t('settings.users.search')}
              className={classes.searchBar}
              onSearch={onSearch}
              size="middle"
            />
          </Col>
          <Col>
            <Button
              category={AnalyticsCategories.USERS}
              action={'export'}
              type="secondary"
              onClick={downloadUsers}
              data-cy="export-users"
            >
              {t('settings.users.export')}
            </Button>
          </Col>
          <Col>
            <Button
              category={AnalyticsCategories.USERS}
              action={'invite'}
              type="primary"
              onClick={goToUserInvitation}
              data-cy="invite-user-button"
            >
              {t('settings.users.invite')}
            </Button>
          </Col>
        </Row>
        <Row className={classes.section}>
          <Col span={24}>
            <Spin spinning={loading || loadingUsers}>
              <UsersTable
                users={users}
                userRole={user?.role?.type}
                businessUnitsAllowed={featuresAllowed.includes('business-units') || false}
              />
            </Spin>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default Users
