import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Tag, message } from 'antd/es'

import { Role, User } from '@cozero/models'
import { routes } from '@cozero/utils'

import Table from '@/molecules/Table'

import Button from '@/atoms/Button'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useInviteUserMutation } from '@/redux/users'
import { truncate } from '@/utils/string'

import classes from './classes.module.less'

interface Props {
  users: User[]
  userRole?: Role['type']
  businessUnitsAllowed: boolean
}

const TRUNCATION_THRESHOLD = 50

const UsersTable = ({ users, userRole = 'user', businessUnitsAllowed }: Props): JSX.Element => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const [inviteUser] = useInviteUserMutation()

  const _handleInvite = async (user: User): Promise<void> => {
    try {
      await inviteUser(user.id)
      return message.success(t('settings.users.success-invitation', { email: user.email }))
    } catch (error) {
      return message.error(error?.data.message ?? error.message)
    }
  }

  const columns = [
    {
      title: t('email'),
      dataIndex: 'email',
      key: 'email',
      render(text: string) {
        return truncate(text, TRUNCATION_THRESHOLD)
      },
      sorter: (a: User, b: User) => a.email.localeCompare(b.email),
    },
    {
      title: t('name'),
      dataIndex: 'firstName',
      key: 'firstName',
      render(text: string, record: User) {
        return `${record.firstName || ''} ${record.lastName || ''}`
      },
    },
    {
      title: t('job'),
      dataIndex: 'jobDescription',
      key: 'jobDescription',
    },
    {
      title: t('settings.users.role'),
      dataIndex: ['role', 'name'],
      key: 'role',
    },
    {
      title: t('settings.users.confirmed'),
      key: 'confirmed',
      render(text: string, record: User) {
        return record.confirmed ? <Tag color="green">{t('yes')}</Tag> : <Tag>{t('no')}</Tag>
      },
    },
    ...(businessUnitsAllowed
      ? [
          {
            title: t('business-unit.name'),
            dataIndex: ['businessUnit', 'title'],
            key: 'businessUnit',
            render(text: string) {
              return truncate(text, TRUNCATION_THRESHOLD)
            },
          },
        ]
      : []),
    {
      title: t('actions.title'),
      key: 'action',
      render(text: string, record: User) {
        return (
          <span className={classes.actions}>
            <Button
              category={AnalyticsCategories.USERS}
              action="Edit user"
              disabled={userRole !== 'admin' && record.role?.type === 'admin'}
              type="secondary"
              className={classes.tableButton}
              onClick={() =>
                navigate(routes.settings.editUser.replace(':id', record.id.toString()))
              }
            >
              {t('actions.edit.title')}
            </Button>

            {!record.confirmed && (
              <Button
                category={AnalyticsCategories.USERS}
                action={'resend-invite'}
                disabled={!['admin', 'manager'].includes(userRole) && record.role?.type === 'admin'}
                type="secondary"
                className={classes.tableButton}
                onClick={() => _handleInvite(record)}
              >
                {t('actions.resend-invitation')}
              </Button>
            )}
          </span>
        )
      },
    },
  ]

  return (
    <Table
      scroll={{ x: 'max-content' }}
      columns={columns}
      dataSource={users}
      rowKey="id"
      className={classes.table}
    />
  )
}

export default UsersTable
