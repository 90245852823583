import { SearchLogEntryDto } from '@cozero/dtos'
import { OrganizationCarbonFootprintSearchDto } from '@cozero/dtos'
import { LogEntry, Page } from '@cozero/models'
import { logApiGatewayClient } from '@cozero/uris'

import { PARTIAL_LIST } from '@/redux/types'
import { providesList } from '@/utils/redux'

import apiSlice from '../api'

import { TAG_LOG_ENTRY, TAG_PAGINATED_LOG_ENTRIES } from './tags'

export const GET_LOG_ENTRY = 'getLogEntry'
export const GET_PAGINATED_LOG_ENTRIES = 'getPaginatedLogEntries'
export const SEARCH_LOG_ENTRIES = 'getSearchLogEntries'

const logEntriesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [GET_LOG_ENTRY]: builder.query<LogEntry, number | string>({
      query: (id) => ({
        url: logApiGatewayClient.logEntries.GET_ONE.replace(':id', id.toString()),
        method: 'GET',
        params: {
          id,
        },
      }),
      providesTags: (result) => [{ type: TAG_LOG_ENTRY, id: result?.id }],
    }),
    [GET_PAGINATED_LOG_ENTRIES]: builder.query<
      Page<LogEntry>,
      {
        logIds: number[]
        pageNumber?: number
        pageSize?: number
      }
    >({
      query: (logEntry) => ({
        url: logApiGatewayClient.logEntries.GET_MANY,
        method: 'GET',
        params: logEntry,
      }),
      providesTags: (result) =>
        providesList<LogEntry[], typeof TAG_PAGINATED_LOG_ENTRIES>(
          result?.data,
          TAG_PAGINATED_LOG_ENTRIES,
          PARTIAL_LIST,
        ),
    }),
    [SEARCH_LOG_ENTRIES]: builder.query<
      Page<OrganizationCarbonFootprintSearchDto>,
      SearchLogEntryDto
    >({
      query: (params) => ({
        url: logApiGatewayClient.logEntries.SEARCH,
        method: 'GET',
        params,
      }),
    }),
  }),
})

export const {
  useGetLogEntryQuery,
  useLazyGetLogEntryQuery,
  useGetPaginatedLogEntriesQuery,
  useGetSearchLogEntriesQuery,
} = logEntriesApiSlice

export default logEntriesApiSlice
