import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Popconfirm } from 'antd/es'

import moment from 'moment'

import { ActivityDataSource, EmissionFactorWithIncludes, LogSorter, Page } from '@cozero/models'
import { EmissionFactor } from '@cozero/models'

import Button from '@/atoms/Button'
import Tooltip from '@/atoms/Tooltip'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useFactorContext } from '@/contexts/factor'
import useGenerateData, { GenerateColumn } from '@/hooks/useGenerateData'
import { useAppSelector } from '@/redux'
import { getIsManagerOrAdmin, selectUserOrganization } from '@/redux/auth'
import { createActivityDataSourceName } from '@/utils/factors'
import { formatNumber, isNumeric } from '@/utils/number'
import { truncate } from '@/utils/string'

import classes from './classes.module.less'

interface FactorProps {
  isPeriodClosed?: boolean
  factors?: Page<EmissionFactorWithIncludes>
  setSelectedFactor: (factor: EmissionFactorWithIncludes) => void
  tableSorter: LogSorter[] | undefined
  businessUnitsAllowed: boolean
}

const useColumns = ({
  isPeriodClosed,
  tableSorter,
  setSelectedFactor,
}: FactorProps): {
  columnsData: GenerateColumn<EmissionFactorWithIncludes>[]
  deletedProduct: EmissionFactor | undefined
} => {
  const { t } = useTranslation('common')
  const organization = useAppSelector(selectUserOrganization)
  const isManagerOrAdmin = useAppSelector(getIsManagerOrAdmin)
  const { deleteCustomFactor } = useFactorContext()
  const { getInitialOrder } = useGenerateData()
  const [deletedProduct] = useState<EmissionFactor | undefined>()

  const columnsData: GenerateColumn<EmissionFactorWithIncludes>[] = useMemo(
    () => [
      {
        title: () => <span className={classes.columnTitle}>{t('factors.activity-data')}</span>,
        dataIndex: 'factors.activity-data',
        key: 'factors',
        sortOrder: getInitialOrder('factors', tableSorter),
        width: 200,
        render(_, factor) {
          return createActivityDataSourceName(factor.activityDataSource as ActivityDataSource)
        },
      },
      {
        title: t('factors.value'),
        key: 'value',
        sorter: true,
        sortOrder: getInitialOrder('value', tableSorter),
        render(_, factor) {
          return isNumeric(factor.value) ? (
            <Tooltip title={factor.value}>{formatNumber(factor.value)}</Tooltip>
          ) : (
            '-'
          )
        },
      },
      {
        title: t('factors.unit'),
        dataIndex: 'unit',
        key: 'unit',
        sortOrder: getInitialOrder('unit', tableSorter),
        render(_, factor) {
          return factor.unit ? (factor.unit.name as string) : 'EmissionFactor'
        },
      },
      {
        title: t('factors.source'),
        key: 'origin',
        sortOrder: getInitialOrder('origin', tableSorter),
        render(_, factor) {
          const originName: string | undefined = factor?.origin?.name
          if (originName) {
            return originName
          }

          const organizationName: string | undefined = factor.organizationId
            ? organization?.name
            : undefined
          if (organizationName) {
            return organizationName
          }

          return 'Cozero'
        },
      },
      {
        title: t('factors.region'),
        key: 'territory',
        sortOrder: getInitialOrder('territory', tableSorter),
        render(_, factor) {
          return factor.territory.name as string
        },
      },
      {
        title: t('factors.startDate'),
        key: 'validityStartDate',
        sorter: true,
        sortOrder: getInitialOrder('validityStartDate', tableSorter),
        render(_, factor) {
          return moment(factor.validityStartDate).year() as unknown as string
        },
      },
      {
        title: t('factors.type'),
        key: 'type',
        sorter: true,
        sortOrder: getInitialOrder('type', tableSorter),
        render(_, factor) {
          return factor.modeledImpact
            ? `${t('factors.modeled-impact')} - ${factor.modeledImpact.name as string}`
            : t('factors.direct')
        },
      },
      {
        title: t('factors.supplier'),
        key: 'name',
        sorter: true,
        sortOrder: getInitialOrder('name', tableSorter),
        dataIndex: ['supplier', 'name'],
        render(_, factor) {
          return factor.supplier?.name as string
        },
      },
      {
        title: t('factors.description'),
        key: 'description',
        sorter: true,
        sortOrder: getInitialOrder('description', tableSorter),
        render(_, factor) {
          return (
            <Tooltip title={factor?.description as string}>
              {truncate(factor?.description as string, 40)}
            </Tooltip>
          )
        },
      },
      {
        title: t('actions.title-plural'),
        key: 'action',
        render(_, factor) {
          if (!isManagerOrAdmin || isPeriodClosed || !factor.editable) {
            return ''
          }
          return (
            <div>
              <Button
                action={'edit'}
                category={AnalyticsCategories.FACTORS}
                onClick={() => {
                  setSelectedFactor(factor)
                }}
                className={classes.editButton}
              >
                {factor.organizationId ? t('actions.edit.title') : t('factors.create-factor')}
              </Button>
              {factor.organizationId && (
                <Popconfirm
                  title={t('factors.delete-warning')}
                  onConfirm={async () => {
                    await deleteCustomFactor(factor.id)
                  }}
                  okText={t('yes')}
                  cancelText={t('no')}
                >
                  <Button
                    action={'delete'}
                    category={AnalyticsCategories.FACTORS}
                    type="primary"
                    color="danger"
                    className={classes.editButton}
                  >
                    {t('actions.delete')}
                  </Button>
                </Popconfirm>
              )}
            </div>
          )
        },
      },
    ],
    [JSON.stringify(tableSorter)],
  )

  return {
    columnsData,
    deletedProduct,
  }
}

export default useColumns
