/* eslint react-hooks/exhaustive-deps: 2 */
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineEyeOff, HiOutlineViewBoards } from 'react-icons/hi'

import { Checkbox, Space } from 'antd'
import { Col, Popover, Row } from 'antd/es'

import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons'
import classnames from 'classnames'

import { LogEntriesTableSortingKey, SortingDirection } from '@cozero/models'

import { ColumnSorting } from '@/pages/Log/LocationLogs/hooks/useSorting'

import classes from './TableHeaderCellPopover.module.less'

export const TableHeaderCellPopover = ({
  title,
  sortingKey,
  sorting,
  setSorting,
}: {
  title: string
  sortingKey?: LogEntriesTableSortingKey
  sorting?: ColumnSorting
  setSorting: (sorting: ColumnSorting) => void
}): JSX.Element => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const saveAppliedSorting = useCallback(
    (direction: SortingDirection): void => {
      if ((sorting?.direction === direction && sorting.key === sortingKey) || !sortingKey) {
        setSorting(null)
      } else {
        setSorting({ key: sortingKey, direction })
      }
      setOpen(false)
    },
    [sorting, sortingKey, setSorting],
  )

  const popoverContent = (
    <div className={classes.popover}>
      {!!sortingKey && (
        <>
          <Row
            onClick={(e) => {
              saveAppliedSorting(SortingDirection.ascending)
            }}
          >
            <Col span={22}>
              <Space>
                <SortingCaret />
                <span>{t('log.log-entries-overview.popover.sort-ascending')}</span>
              </Space>
            </Col>
            <Col span={2}>
              <Checkbox
                checked={
                  sorting?.key === sortingKey && sorting?.direction === SortingDirection.ascending
                }
              ></Checkbox>
            </Col>
          </Row>
          <Row
            onClick={(e) => {
              saveAppliedSorting(SortingDirection.descending)
            }}
          >
            <Col span={22}>
              <Space>
                <SortingCaret />
                <span>{t('log.log-entries-overview.popover.sort-descending')}</span>
              </Space>
            </Col>
            <Col span={2}>
              <Checkbox
                checked={
                  sorting?.key === sortingKey && sorting?.direction === SortingDirection.descending
                }
              ></Checkbox>
            </Col>
          </Row>
        </>
      )}
      <Row>
        <Col span={22}>
          <Space>
            <HiOutlineEyeOff />
            <span>{t('log.log-entries-overview.popover.hide-this-column')}</span>
          </Space>
        </Col>
        <Col span={2}></Col>
      </Row>
      <Row>
        <Col span={22}>
          <Space>
            <HiOutlineViewBoards />
            <span>{t('log.log-entries-overview.popover.manage-all-columns')}</span>
          </Space>
        </Col>
        <Col span={2}></Col>
      </Row>
    </div>
  )

  return (
    <Popover
      content={popoverContent}
      placement={'bottom'}
      trigger="click"
      showArrow={false}
      open={open}
      onOpenChange={setOpen}
    >
      <div className={classes.title}>
        <span>{title}</span>
        {sorting?.direction && sorting?.key === sortingKey && (
          <SortingCaret sorted={sorting?.direction} />
        )}
      </div>
    </Popover>
  )
}

const SortingCaret = ({ sorted }: { sorted?: SortingDirection }): JSX.Element => {
  return (
    <span className={classnames(classes.sortIcon, !!sorted && classes.gray)}>
      <CaretUpFilled
        className={classnames(
          classes.squeezeIcon,
          sorted === SortingDirection.ascending ? classes.blueIcon : undefined,
        )}
      />
      <CaretDownFilled
        className={sorted === SortingDirection.descending ? classes.blueIcon : undefined}
      />
    </span>
  )
}
