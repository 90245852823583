import { useMemo } from 'react'

import useSWR from 'swr'

import { EmissionFactorWithIncludes, LogSorter, PageFilter } from '@cozero/models'
import { logApiGatewayClient } from '@cozero/uris'

import { SWRProduct } from '../types/SWRProduct'
import axios from '../utils/axios'

/**
 * Hook to search for logs.
 * @param {Object} params
 * @param {number} params.page
 * @param {number} params.pageSize
 * @param {PageFilter[]} params.filters
 * @param {LogSorter[]} params.sorters
 */
const useEmissionFactorsData = ({
  requestedBusinessUnitId,
  activityDataSourceId,
  used,
  custom,
  page,
  pageSize,
  filters,
  sorters,
}: {
  requestedBusinessUnitId: string
  activityDataSourceId: string
  used: boolean
  custom: boolean
  page: number
  pageSize: number
  filters?: Omit<PageFilter, 'options'>[]
  sorters?: LogSorter[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}): SWRProduct<{ data: EmissionFactorWithIncludes[]; total: number }> => {
  const memoedFilters = useMemo(() => filters, [filters])
  const memoedSorters = useMemo(() => sorters, [filters])

  const { data, mutate, error, isValidating } = useSWR(
    [
      logApiGatewayClient.factors.GET_MANY,
      useMemo(
        (): {
          activityDataSourceId: string
          requestedBusinessUnitId: string
          used: boolean
          custom: boolean
          page: number
          pageSize: number
          filters?: Omit<PageFilter, 'options'>[]
          sorters?: LogSorter[]
          logEntryId?: string
        } => ({
          activityDataSourceId,
          requestedBusinessUnitId,
          used,
          custom,
          page,
          pageSize,
          filters: memoedFilters,
          sorters: memoedSorters,
        }),
        [
          page,
          pageSize,
          memoedFilters,
          memoedSorters,
          activityDataSourceId,
          requestedBusinessUnitId,
        ],
      ),
    ],
    async (url, { page, pageSize, filters, sorters }) => {
      const { data } = await axios.post<{ data: EmissionFactorWithIncludes[]; total: number }>(
        url,
        {
          requestedBusinessUnitId,
          page,
          pageSize,
          filters,
          used,
          custom,
          sorters,
        },
      )

      if (data !== undefined && data.data !== undefined) {
        data.data = data.data.map((value: EmissionFactorWithIncludes) => {
          const { children, ...rest } = value
          return rest as EmissionFactorWithIncludes
        })
      }

      return data
    },
    { revalidateOnFocus: false },
  )

  return {
    data,
    mutate,
    error,
    loading: !error && isValidating,
  }
}

export default useEmissionFactorsData
