/* eslint react-hooks/exhaustive-deps: 2 */

import React from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { HiOutlineDuplicate } from 'react-icons/hi'

import { Button, Col, Image, Row } from 'antd/es'
import { ColumnType } from 'antd/es/table'

import Icon from '@ant-design/icons'
import classNames from 'classnames'

import { OrganizationCarbonFootprintSearchDto } from '@cozero/dtos'
import { LogEntriesTableSortingKey, User } from '@cozero/models'

import Avatar from '@/atoms/Avatar'
import Tag from '@/atoms/Tag'
import Text from '@/atoms/Text'
import Tooltip from '@/atoms/Tooltip'

import { copyToClipboard } from '@/utils/clipboard'
import { prettifyDate } from '@/utils/date'
import { formatNumber } from '@/utils/number'

import classes from '../Log.module.less'

const DEFAULT_CELL_WIDTH = 200
const DEFAULT_CELL_WIDTH_SMALL = 160

type TranslationFn = TFunction<'common', undefined>

const renderId = (id: string, t: TranslationFn): React.ReactNode => (
  <Row wrap={false} align="middle" justify="start">
    <Col style={{ display: 'flex', direction: 'rtl' }}>
      <Tag size="sm">
        <Tooltip content={id} align={{ offset: [0, -4] }}>
          {/* We limit the width oft the text so it only displays 3 chars */}
          {/* Doing this instead of stripping the text in js, allow the user to search for the full id in the page and have a match */}
          <Text className={classes.cellText} style={{ width: 45 }}>
            {/* We are adding a few non breakable sapce to make sure the id is long enough to display 3 chars before overflowing */}
            {/* If it only has 4 chars, then on Firefox it oveflows after 2 chars */}
            {id}&nbsp;&nbsp;&nbsp;&nbsp;
          </Text>
        </Tooltip>
      </Tag>
    </Col>
    <Col>
      <Button
        type="text"
        icon={<Icon component={() => <HiOutlineDuplicate />} />}
        onClick={(event) => {
          // Prevent the event from triggering the row navigation
          event.preventDefault()
          event.stopPropagation()

          copyToClipboard(id, t)
        }}
      />
    </Col>
  </Row>
)

const renderTags = (tags: string[]): React.ReactNode => (
  <Row wrap={false} gutter={16} align="middle">
    {tags.map((tag) => (
      <Col key={tag} flex={1} style={{ display: 'flex', minWidth: 0 }}>
        <Tag size="sm" className={classes.cellText}>
          <Tooltip content={tag} align={{ offset: [0, -4] }}>
            {tag}
          </Tooltip>
        </Tag>
      </Col>
    ))}
  </Row>
)

const renderDate = (date: string, options: { showTime?: boolean } = {}): React.ReactNode => (
  <Tag size="sm" className={classes.cellText}>
    <Tooltip
      content={prettifyDate(date, `MMM DD, YYYY${options.showTime ? ', h:mm a' : ''}`)}
      align={{ offset: [0, -4] }}
    >
      {prettifyDate(date, 'MMM DD, YYYY ')}
    </Tooltip>
  </Tag>
)

const renderUser = (
  user: Omit<Partial<User>, 'photo'> & { photo?: Partial<User['photo']> },
): React.ReactNode => (
  <Row wrap={false} align="middle" gutter={6}>
    <Col>
      <Avatar
        image={user.photo}
        firstName={user.firstName}
        lastName={user.lastName}
        size="xs"
        showTooltip={false}
      />
    </Col>
    <Col flex={1} style={{ display: 'flex', minWidth: 0 }}>
      <Text className={classes.cellText}>
        <Tooltip content={user.firstName} align={{ offset: [0, -4] }}>
          {user.firstName}
        </Tooltip>
      </Text>
    </Col>
  </Row>
)

const renderText = (text: string): React.ReactNode => (
  <Text className={classes.cellText} ellipsis>
    <Tooltip content={text} align={{ offset: [0, -4] }}>
      {text}
    </Tooltip>
  </Text>
)

const renderCategory = (
  category?: NonNullable<OrganizationCarbonFootprintSearchDto['category']>,
): React.ReactNode => (
  <Row wrap={false} gutter={10} align="middle">
    {category?.image?.url && (
      <Col>
        <Image width={30} src={category.image.url} preview={false} />
      </Col>
    )}
    <Col flex={1} style={{ display: 'flex', alignItems: 'center' }}>
      <Text className={classes.cellText} ellipsis>
        <Tooltip content={category?.name as string} align={{ offset: [0, -4] }}>
          {category?.name as string}
        </Tooltip>
      </Text>
    </Col>
  </Row>
)

const renderEmissions = (value: number): React.ReactNode => {
  return (
    <Row wrap={false} justify="end">
      <Col>
        <Text className={classNames(classes.cellText, classes.emissions)} ellipsis>
          {formatNumber(value)}
        </Text>
      </Col>
    </Row>
  )
}

type TableColumns = (ColumnType<OrganizationCarbonFootprintSearchDto> & {
  sortingKey?: LogEntriesTableSortingKey
})[]

export const useTableColumns = (): {
  columns: TableColumns
} => {
  const { t } = useTranslation()

  const columns = React.useMemo<TableColumns>(
    (): TableColumns => [
      {
        title: t('log.log-entries-overview.table-columns.log-id'),
        sortingKey: 'logId',
        dataIndex: ['log', 'id'],
        width: DEFAULT_CELL_WIDTH_SMALL,
        render: (id: string) => renderId(id, t),
      },
      {
        title: t('log.log-entries-overview.table-columns.log-entry-title'),
        width: DEFAULT_CELL_WIDTH,
        sortingKey: 'title',
        dataIndex: ['logEntry', 'title'],
        render: renderText,
      },
      {
        title: t('log.log-entries-overview.table-columns.log-description'),
        width: DEFAULT_CELL_WIDTH,
        sortingKey: 'description',
        dataIndex: ['log', 'description'],
        render: renderText,
      },
      {
        title: t('log.log-entries-overview.table-columns.category'),
        width: DEFAULT_CELL_WIDTH,
        sortingKey: 'category',
        dataIndex: ['category'],
        render: renderCategory,
      },
      {
        title: t('log.log-entries-overview.table-columns.subcategory'),
        width: DEFAULT_CELL_WIDTH,
        sortingKey: 'subcategory',
        dataIndex: ['subcategory', 'name'],
        render: renderText,
      },
      {
        title: t('log.log-entries-overview.table-columns.business-unit'),
        width: DEFAULT_CELL_WIDTH,
        sortingKey: 'businessUnit',
        dataIndex: ['businessUnit', 'name'],
        render: (businessUnit: string) => renderTags([businessUnit]),
      },
      {
        title: t('log.log-entries-overview.table-columns.location'),
        width: DEFAULT_CELL_WIDTH,
        sortingKey: 'location',
        dataIndex: ['location', 'name'],
        render: (text: string) => renderTags([text]),
      },
      {
        title: t('log.log-entries-overview.table-columns.files'),
        width: DEFAULT_CELL_WIDTH,
        sortingKey: 'files',
        dataIndex: ['filesTotal'],
        render: (filesTotal: number) => renderText(`${filesTotal}`),
      },
      {
        title: t('log.log-entries-overview.table-columns.responsible'),
        width: DEFAULT_CELL_WIDTH,
        sortingKey: 'owner',
        dataIndex: ['owner'],
        render: renderUser,
      },
      {
        title: t('log.log-entries-overview.table-columns.supplier'),
        width: DEFAULT_CELL_WIDTH,
        sortingKey: 'supplier',
        dataIndex: 'supplier',
        render: (supplier: OrganizationCarbonFootprintSearchDto['supplier']) =>
          renderUser({
            firstName: supplier?.name,
            photo: { url: supplier?.logo?.url || '' },
          }),
      },
      {
        title: t('log.log-entries-overview.table-columns.period-start'),
        width: DEFAULT_CELL_WIDTH,
        sortingKey: 'startDate',
        dataIndex: ['log', 'startDate'],
        render: (date: string) => renderDate(date),
      },
      {
        title: t('log.log-entries-overview.table-columns.period-end'),
        width: DEFAULT_CELL_WIDTH,
        sortingKey: 'endDate',
        dataIndex: ['log', 'endDate'],
        render: (date: string) => renderDate(date),
      },
      {
        title: t('log.log-entries-overview.table-columns.created-at'),
        width: DEFAULT_CELL_WIDTH,
        sortingKey: 'createdAt',
        dataIndex: ['log', 'createdAt'],
        render: (date: string) => renderDate(date, { showTime: true }),
      },
      {
        title: t('log.log-entries-overview.table-columns.territory'),
        width: DEFAULT_CELL_WIDTH,
        sortingKey: 'territory',
        dataIndex: 'territory',
        render: (territory) => renderTags([territory.name]),
      },
      {
        title: t('log.log-entries-overview.table-columns.organization-tags'),
        width: DEFAULT_CELL_WIDTH,
        dataIndex: ['logEntry', 'tags'],
        render: (tags?: string[]) => (tags?.length ? renderTags(tags) : null),
      },
      {
        title: t('log.log-entries-overview.table-columns.product-tags'),
        width: DEFAULT_CELL_WIDTH,
        dataIndex: ['product', 'tags'],
        render: (tags?: string[]) => (tags?.length ? renderTags(tags) : null),
      },
      {
        title: t('log.log-entries-overview.table-columns.calculation-method'),
        width: DEFAULT_CELL_WIDTH,
        sortingKey: 'calculationMethod',
        dataIndex: 'calculationMethod',
        render: (calculationMethod) => renderTags([calculationMethod.name]),
      },
      {
        title: t('log.log-entries-overview.table-columns.emissions'),
        width: DEFAULT_CELL_WIDTH_SMALL,
        fixed: 'right',
        sortingKey: 'organizationEmissions',
        dataIndex: 'emissionsSum',
        render: renderEmissions,
      },
    ],
    [t],
  )

  return { columns }
}
