/* eslint react-hooks/exhaustive-deps: 2 */

import React, { ReactElement, ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  HiDotsVertical,
  HiInformationCircle,
  HiOutlineChartBar,
  HiPencilAlt,
  HiPlus,
  HiTrash,
} from 'react-icons/hi'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'

import { Col, Divider, Dropdown, Menu, Popconfirm, Row, Spin, message } from 'antd/es'
import { Store } from 'antd/es/form/interface'

import {
  Board as IBoard,
  LogSorter,
  PageFilter,
  PreviewClosedPeriod,
  Report,
  ReportCategory,
  ReportType,
} from '@cozero/models'
import { routes } from '@cozero/utils'

import ReportsModal from '@/pages/Share/Modals/ReportsModal'

import CustomBoard from '@/organisms/CustomBoard'
import LogsTable from '@/organisms/LogsTable'
import OverviewRow from '@/organisms/OverviewRow'

import { OverviewCardProps } from '@/molecules/OverviewCard'

import Alert from '@/atoms/Alert'
import Button from '@/atoms/Button'
import HighlightValue from '@/atoms/HighlightValue'
import LoadingSpinner from '@/atoms/LoadingSpinner'
import Pill from '@/atoms/Pill'
import Text from '@/atoms/Text'
import Tooltip from '@/atoms/Tooltip'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useBoardsContext } from '@/contexts/boards'
import { useFiltersContext } from '@/contexts/filters'
import { useClosedPeriod } from '@/hooks/useClosedPeriod'
import { useAppSelector } from '@/redux'
import { getFeaturesAllowed, getIsManager, getIsManagerOrAdmin, selectUser } from '@/redux/auth'
import { selectSelectedBusinessUnit } from '@/redux/businessUnits'
import { CINDER_BLUE_60, COZERO_BLUE_80 } from '@/styles/variables'
import { PersistedBoardSettings } from '@/types/general'
import { formatNumber } from '@/utils/number'

import classes from './CustomReport.module.less'

const DEFAULT_PAGE_SIZE = 15

const CustomReport = (): ReactElement => {
  const { id } = useParams()
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const selectedBusinessUnit = useSelector(selectSelectedBusinessUnit)
  const featuresAllowed = useAppSelector(getFeaturesAllowed)
  const isManager = useAppSelector(getIsManager)
  const isManagerOrAdmin = useAppSelector(getIsManagerOrAdmin)
  const user = useAppSelector(selectUser)
  const {
    filters,
    saveFilters,
    sorters,
    pageNumber: currentPage,
    savePageNumber: setCurrentPage,
    saveSorters,
  } = useFiltersContext()
  const [loadingBoard, setLoadingBoard] = useState(true)
  const [customReportFilters, setCustomReportFilters] = useState(() =>
    filters?.filter((filter) => filter.logType === 'location' || !filter.logType),
  )
  const [customReportSorters, setCustomReportSorters] = useState(
    sorters?.filter((sorter) => sorter.logType === 'location' || !sorter.logType),
  )
  const [reportOptionLoading, setReportOptionLoading] = useState(true)
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE)
  const [openReportMenu, setOpenReportMenu] = useState<boolean>(false)
  const [reportCategories, setReportCategories] = useState<ReportCategory[]>([])
  const {
    getClosedPeriod,
    closedPeriod,
    deleteClosedPeriod,
    loadingPreview,
    previewKeyStats,
    keyStats,
  } = useClosedPeriod()
  const [closedPeriodBoardSettings, setClosedPeriodBoardSettings] =
    useState<PersistedBoardSettings>()
  const {
    getBoards,
    selectedBoard,
    boards,
    getBoard,
    editReportsOfBoard,
    searchReportCategories,
    setSelectedBoard,
  } = useBoardsContext()

  const fetchBoardData = React.useCallback(
    async (board: IBoard) => {
      if (closedPeriod) {
        const filtersTemp: PageFilter[] = [
          ...(closedPeriod.organizationLevel === 'businessUnit'
            ? ([
                {
                  key: 'businessUnit',
                  value: [closedPeriod.businessUnitId],
                  type: 'relationship',
                  conditions: [],
                  selectedCondition: {
                    key: 'in',
                    label: 'Is',
                  },
                  label: 'businessUnitId',
                  logType: 'location',
                },
              ] as PageFilter[])
            : []),
          {
            filters: [
              {
                key: 'startDate',
                value: closedPeriod.startDate,
                type: 'date',
                conditions: [],
                selectedCondition: {
                  key: 'gte',
                  label: 'gte',
                },
                label: 'startDate',
              },
              {
                key: 'endDate',
                value: closedPeriod.endDate,
                type: 'date',
                conditions: [],
                selectedCondition: {
                  key: 'lte',
                  label: 'lte',
                },
                label: 'endDate',
              },
            ],
            type: 'dateRange',
          } as PageFilter,
        ].filter((x) => x.type)
        saveFilters(filtersTemp)
        const boardData = await getBoard({
          boardId: board.id,
          user: user,
          closedPeriodId: closedPeriod.id,
        })
        if (boardData) {
          setSelectedBoard(boardData)
        }
        setClosedPeriodBoardSettings({
          board,
          filters: filtersTemp.filter((x) => x.key !== 'closedPeriodId'),
          closedPeriodId: closedPeriod.id,
        })
      }
    },
    [closedPeriod, getBoard, setSelectedBoard, user, saveFilters],
  )

  const editReports = React.useCallback(
    async (reports: Store, boardId: number): Promise<void> => {
      setLoadingBoard(true)
      await editReportsOfBoard(reports, boardId, undefined, Number(id))
      if (selectedBoard) {
        await fetchBoardData(selectedBoard)
      } else {
        message.error(t('share.reports.errors.add'))
      }
      setLoadingBoard(false)
    },
    [editReportsOfBoard, fetchBoardData, id, selectedBoard, t],
  )

  const changeSorting = React.useCallback(
    async (sorters: LogSorter[]) => {
      saveSorters(sorters)
      setCustomReportSorters(sorters)
    },
    [saveSorters, setCustomReportSorters],
  )

  const fetchReportCategories = React.useCallback(
    async (search: string): Promise<void> => {
      setReportOptionLoading(true)
      const data = await searchReportCategories(search)
      // We should not allow to add statistic reports as they are not supported by CustomBoard Component
      const filteredData = data.map((category) => {
        return {
          ...category,
          reports: category.reports.filter((x) => x.type !== ReportType.STATISTIC) as Report[],
        }
      })
      setReportCategories(filteredData)
      setReportOptionLoading(false)
    },
    [searchReportCategories],
  )

  useEffect(() => {
    setCustomReportFilters(filters as PageFilter[])
  }, [filters])

  useEffect(() => {
    if (id) {
      getClosedPeriod(id)
    }
  }, [id, getClosedPeriod])

  useEffect(() => {
    if (boards.length > 0) {
      return
    }

    async function fetchBoards(): Promise<void> {
      await getBoards()
      fetchReportCategories('')
    }

    fetchBoards()
  }, [boards, fetchReportCategories, getBoards])

  useEffect(() => {
    if (selectedBusinessUnit?.key) {
      fetchReportCategories('')
    }
  }, [selectedBusinessUnit?.key, fetchReportCategories])

  useEffect(() => {
    if (!boards?.length) {
      return
    }

    async function fetchBoards(): Promise<void> {
      setLoadingBoard(true)
      const board = boards.find((board) => board.type === 'custom-report')

      if (board && closedPeriod) {
        await fetchBoardData(board)
        await previewKeyStats({
          startDate: closedPeriod.startDate,
          endDate: closedPeriod.endDate,
          organizationLevel: closedPeriod.organizationLevel,
          businessUnitId: closedPeriod.businessUnitId,
        })
      }
      setLoadingBoard(false)
    }

    fetchBoards()
  }, [boards, closedPeriod, fetchBoardData, previewKeyStats])

  const menu = React.useMemo(
    () => (
      <Menu style={{ width: 256 }} mode="vertical">
        <Menu.ItemGroup>
          <Menu.Item key="1">
            <Button
              category={AnalyticsCategories.CLOSED_PERIODS}
              action="Edit closed period"
              type="text"
              prefixIcon={<HiPencilAlt />}
              onClick={() => {
                if (id) {
                  navigate(routes.log.carbonFootprint.editClosedPeriod.replace(':id', id))
                }
              }}
            >
              {t('log.closed-period.edit')}
            </Button>
          </Menu.Item>
          <Menu.Item key="2">
            <Tooltip
              placement="bottom"
              title={t('custom-reports.closed-period.delete-disabled')}
              disabled={!closedPeriod?.usedInAct}
            >
              <Popconfirm
                title={t('log.closed-period.delete-confirmation')}
                onConfirm={async () => {
                  if (id) {
                    await deleteClosedPeriod(id)
                    navigate(routes.log['closed-periods'])
                  }
                }}
                disabled={closedPeriod?.usedInAct}
              >
                <Button
                  category={AnalyticsCategories.CLOSED_PERIODS}
                  action="Delete closed period"
                  type="text"
                  prefixIcon={<HiTrash />}
                  color="danger"
                  disabled={closedPeriod?.usedInAct}
                >
                  {t('log.closed-period.delete')}
                </Button>
              </Popconfirm>
            </Tooltip>
          </Menu.Item>
        </Menu.ItemGroup>
      </Menu>
    ),
    [deleteClosedPeriod, id, navigate, t, closedPeriod?.usedInAct],
  )

  const EmptyBoard = React.useCallback(
    () => (
      <Row className={classes.reportsEmptyState}>
        <Col span={24}>
          <HiOutlineChartBar className={classes.icon} size={20} />
        </Col>
        <Col span={24}>
          <span className={classes.title}>{t('log.closed-period.empty-state-reports.title')}</span>
        </Col>
        <Col span={12}>
          <span className={classes.text}>{t('log.closed-period.empty-state-reports.text')}</span>
        </Col>
        <Col className={classes.buttonWrapper} span={24}>
          <Button
            size="lg"
            type="primary"
            category={AnalyticsCategories.ONBOARDING}
            action="Open reports modal"
            className={classes.cardBtn}
            onClick={() => setOpenReportMenu(true)}
            prefixIcon={<HiPlus />}
          >
            {t('onboarding.home.no-graphs-card.btn-text')}
          </Button>
        </Col>
      </Row>
    ),
    [t],
  )

  const Wrapper = React.useCallback(
    ({ children, stats }: { children: ReactNode; stats: PreviewClosedPeriod | undefined }) => {
      const overviewDataLocationType: OverviewCardProps[] = [
        {
          content: (
            <div>
              <HighlightValue
                value={formatNumber(stats?.emissionsDataValue || 0) ?? ''}
                unit={t('log.co2-unit')}
              />
              <Row>
                <Pill color="cinder-blue">
                  <span className={classes.totalLogsPill}>{stats?.emissionsDataCount} logs</span>
                </Pill>
              </Row>
            </div>
          ),
          headerTitle: t('log.overview-stats.organization-footprint'),
          tooltip: {
            triggerElement: <HiInformationCircle color={COZERO_BLUE_80} />,
            subtitle: t('log.closed-period.overview-tooltip'),
          },
        },
      ]
      return (
        <Row>
          <Row className={classes.header}>
            <Col span={8}>
              <h3 className={classes.title}>{closedPeriod?.name}</h3>
              <Text size="xl" color="secondary">
                {t('log.closed-period.header-description')}
              </Text>
            </Col>
            <Col span={8} offset={8} className={classes.optionsButton}>
              <Dropdown overlay={menu}>
                <Button
                  category={AnalyticsCategories.CLOSED_PERIODS}
                  action="Show options menu"
                  type="text"
                  size="lg"
                >
                  <HiDotsVertical color={CINDER_BLUE_60} />
                </Button>
              </Dropdown>
            </Col>
          </Row>
          <Col span={24}>
            <Row>
              <Col span={24}>
                <OverviewRow
                  loadingOverview={loadingPreview}
                  marginBetween={14}
                  overviewData={overviewDataLocationType}
                />
                {children}
              </Col>
            </Row>
          </Col>
        </Row>
      )
    },
    [closedPeriod?.name, loadingPreview, menu, t],
  )

  return (
    <div className={classes.onboardingContainer} data-cy="custom-report-page">
      <Spin spinning={!closedPeriod || loadingPreview || reportOptionLoading}>
        {filters.length && closedPeriod?.id && (
          <Wrapper stats={keyStats}>
            <Row>
              <Col span={24}>
                <Col md={{ span: 5 }} xxl={{ span: 3 }}>
                  <Row justify="end" gutter={[16, 16]}>
                    <Col span={24}>
                      {selectedBoard && !loadingBoard && selectedBoard?.reportToBoards?.length ? (
                        <Button
                          disabled={
                            !isManagerOrAdmin ||
                            (isManager && closedPeriod?.organizationLevel === 'organization')
                          }
                          category={AnalyticsCategories.CLOSED_PERIODS}
                          action={'add-report'}
                          className={classes.editBtn}
                          onClick={() => {
                            setOpenReportMenu(true)
                          }}
                        >
                          {t('share.reports.add-board')}
                        </Button>
                      ) : (
                        ''
                      )}
                    </Col>
                  </Row>
                </Col>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                {selectedBoard?.type === 'custom-report' &&
                !loadingBoard &&
                selectedBoard?.reportToBoards?.length ? (
                  <CustomBoard
                    includeChildrenBUsData
                    persistedBoardSettings={closedPeriodBoardSettings}
                    editable
                    canUpdateWidth={false}
                    closedPeriod={closedPeriod}
                  />
                ) : (
                  ''
                )}
                {!selectedBoard ||
                  (loadingBoard ? (
                    <LoadingSpinner
                      className={classes.spinner}
                      title={t('share.reports.loading-plural')}
                    />
                  ) : (
                    ''
                  ))}
                {!selectedBoard?.reportToBoards?.length && !loadingBoard ? <EmptyBoard /> : ''}
              </Col>
            </Row>
            <Row>
              <Col span={16}>
                <Alert type={'info'} className={classes.alert}>
                  <Text size="xl">{t('custom-report.filter-warning')}</Text>
                </Alert>
              </Col>
              <Col span={24}>
                <Divider />
                {customReportFilters.length && (
                  <LogsTable
                    type={'location'}
                    businessUnitsAllowed={featuresAllowed.includes('business-units') || false}
                    filters={customReportFilters}
                    sorters={customReportSorters}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    defaultPageSize={DEFAULT_PAGE_SIZE}
                    currentPage={currentPage || 1}
                    setCurrentPage={setCurrentPage}
                    selectedBusinessUnit={selectedBusinessUnit ?? undefined}
                    loading={false}
                    setSort={changeSorting}
                  />
                )}
              </Col>
            </Row>
          </Wrapper>
        )}
        {reportCategories?.length && (
          <ReportsModal
            open={openReportMenu}
            onOk={() => setOpenReportMenu(false)}
            onCancel={() => setOpenReportMenu(false)}
            reportCategories={reportCategories}
            editReportsOfBoard={editReports}
          />
        )}
      </Spin>
    </div>
  )
}

export default CustomReport
