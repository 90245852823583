import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { message } from 'antd/es'

import { cloneDeep } from 'lodash-es'
import omit from 'lodash/omit'

import {
  ActivityDataSource,
  BusinessUnit,
  EmissionFactorChangelog,
  EmissionFactorWithIncludes,
  FactorOrigin,
  FactorRequest,
  FactorRequestResponse,
  Location,
  LogSorter,
  Page,
  PageFilter,
  Product,
  Territory,
  Unit,
  User,
} from '@cozero/models'
import { logApiGatewayClient } from '@cozero/uris'

import { ParsedFormValues } from '@/organisms/FactorRequestForm/types'
import { FormValues } from '@/organisms/FactorRequestResponseForm/types'

import { FactorFilterContextInterface } from '@/contexts/factorFilter'
import { SearchFilters } from '@/types/general'

import axios from '../utils/axios'

const defaultFilters: PageFilter[] = []
const defaultSorters: LogSorter[] = [
  {
    id: '3uzmyzm291',
    key: 'createdAt',
    label: 'createdAt',
    selectedSort: 'desc',
  },
  {
    id: '3uzmyzm294',
    key: 'id',
    label: 'id',
    selectedSort: 'desc',
  },
]

function createDefaultFilters(initialState: SearchFilters): SearchFilters {
  const searchState = cloneDeep(initialState || {})
  if (!searchState.filters?.length) {
    searchState.filters = defaultFilters
  }
  if (!searchState.sorters?.length) {
    searchState.sorters = defaultSorters
  }

  return searchState
}

const useFactorFilter = (initialState: SearchFilters): FactorFilterContextInterface => {
  const { t } = useTranslation()
  const [factorRequests, setFactorRequests] = useState<FactorRequest[]>([])
  const [factorRequestsAsSupplier, setFactorRequestsAsSupplier] = useState<FactorRequest[]>([])

  // const [factors, setFactors] = useState<Product[]>([])

  const createCustomFactor = async (
    factorId: number,
    body: Partial<EmissionFactorWithIncludes>,
  ): Promise<EmissionFactorWithIncludes | void> => {
    const { data } = await axios.post<EmissionFactorWithIncludes>(
      logApiGatewayClient.factors.CREATE,
      {
        ...body,
        parent: factorId,
        editable: true,
        custom: true,
      },
    )

    return data
  }

  const [search, setSearchState] = useState<SearchFilters>(createDefaultFilters(initialState))

  const savePageNumber = (pageNumber: number): void => {
    saveSearch({ ...search, pageNumber })
  }

  const saveSearch = (newSearch: SearchFilters): void => {
    setSearchState(newSearch)
  }

  const getFactors = async (query: {
    activityDataSourceId?: number
    used?: boolean
    custom?: true
    logEntryId?: number
    type?: 'product' | 'location'
    page: number
    pageSize: number
    filters?: PageFilter[]
  }): Promise<Page<EmissionFactorWithIncludes> | void> => {
    try {
      const { data } = await axios.post<Page<EmissionFactorWithIncludes>>(
        logApiGatewayClient.factors.GET_MANY,
        {
          activityDataSourceId: query.activityDataSourceId,
          used: query.used,
          custom: query.custom,
          type: query.type,
          logEntryId: query.logEntryId,
          page: query.page,
          pageSize: query.pageSize,
          filters: query.filters?.map((obj) => omit(obj, 'options')) || [],
        },
      )

      return data
    } catch (error) {
      message.error(t('factors.error'))
    }
  }

  async function getFactorsData(): Promise<{
    products: Product[]
    locations: Location[]
    businessUnits: BusinessUnit[]
  }> {
    try {
      const { data } = await axios.get<{
        products: Product[]
        locations: Location[]
        businessUnits: BusinessUnit[]
      }>(logApiGatewayClient.factors.DATA)

      return data
    } catch (error) {
      throw new Error(error)
    }
  }

  async function createFactorRequest(
    factorRequest: ParsedFormValues,
    businessUnitId: number,
  ): Promise<FactorRequest & { responsibleUser: User }> {
    try {
      const { data } = await axios.post<FactorRequest | null>(
        logApiGatewayClient.factorRequests.CREATE,
        { ...factorRequest, businessUnitId },
      )
      return data as FactorRequest & { responsibleUser: User }
    } catch (error) {
      throw new Error(error.response.data.message || error || 'Failed to create factor request')
    }
  }

  async function createFactorRequestResponse(
    factorRequestResponse: FormValues,
  ): Promise<FactorRequestResponse> {
    try {
      const { data } = await axios.post<FactorRequestResponse | null>(
        logApiGatewayClient.factorRequestsResponses.CREATE,
        factorRequestResponse,
      )
      return data as FactorRequestResponse
    } catch (error) {
      throw new Error(
        error.response.data.message || error || 'Failed to create factor request response',
      )
    }
  }

  const updateCustomFactor = async (
    id: number,
    body: Partial<EmissionFactorWithIncludes>,
  ): Promise<EmissionFactorWithIncludes | void> => {
    try {
      const { data } = await axios.put<EmissionFactorWithIncludes>(
        logApiGatewayClient.factors.UPDATE_ONE.replace(':id', `${id}`),
        {
          ...body,
          value: parseFloat(`${body.value}`),
        },
      )
      return data
    } catch (error) {
      throw new Error(error || 'Failed to update custom factor')
    }
  }

  const deleteCustomFactor = async (id: number): Promise<void> => {
    try {
      await axios.delete(logApiGatewayClient.factors.DELETE_ONE.replace(':id', `${id}`))
    } catch (error) {
      throw new Error(error || 'Failed to delete custom factor')
    }
  }

  async function getFactorRequests(businessUnitId: number): Promise<FactorRequest[]> {
    try {
      const { data: fetchedFactorRequests } = await axios.get<FactorRequest[]>(
        logApiGatewayClient.factorRequests.GET_MANY,
        {
          params: { businessUnitId },
        },
      )
      setFactorRequests(fetchedFactorRequests)
      return fetchedFactorRequests
    } catch (error) {
      throw new Error(error || 'Failed to get factor requests')
    }
  }

  async function getFactorRequestsAsSupplier(): Promise<FactorRequest[]> {
    try {
      const { data: fetchedFactorRequests } = await axios.get<FactorRequest[]>(
        logApiGatewayClient.factorRequests.GET_MANY,
      )
      setFactorRequestsAsSupplier(fetchedFactorRequests)
      return fetchedFactorRequests
    } catch (error) {
      throw new Error(error || 'Failed to get factor requests')
    }
  }

  async function saveFactorRequest(
    id: number,
    factorRequest: Partial<FactorRequest>,
  ): Promise<FactorRequest | void> {
    try {
      const { data } = await axios.put<FactorRequest>(
        logApiGatewayClient.factorRequests.UPDATE_ONE.replace(':id', `${id}`),
        factorRequest,
      )
      return data
    } catch (error) {
      throw new Error(error || 'Failed to save factor request')
    }
  }

  async function applyFactorChanges(
    emissionFactorsToUpdate: Partial<EmissionFactorChangelog>[],
  ): Promise<void> {
    await axios.post(logApiGatewayClient.factors.SUBMIT_CHANGE, {
      data: emissionFactorsToUpdate,
    })
  }

  async function getFactorChangelogs(
    page: number,
    pageSize = 2,
  ): Promise<{ docs: EmissionFactorChangelog[]; totalRecords: number }> {
    return (
      await axios.get(logApiGatewayClient.emissionFactorChangelogs.GET_MANY, {
        params: {
          page,
          pageSize,
        },
      })
    ).data
  }

  async function getFactorFilters(): Promise<{
    factorOrigins: FactorOrigin[]
    units: Unit[]
    activityDataSources: ActivityDataSource[]
    territories: Territory[]
  }> {
    return (await axios.get(logApiGatewayClient.factors.GET_FILTERS)).data
  }

  const saveFilters = (filters: PageFilter[]): void => {
    saveSearch({
      ...search,
      filters: filters?.map(
        (x) =>
          ({
            ...omit(x, ['options', 'conditions']),
            options: [],
            conditions: [],
          } as PageFilter),
      ),
      pageNumber: 1,
    })
  }

  const saveSorters = (sorters: LogSorter[]): void => {
    saveSearch({ ...search, sorters, pageNumber: 1 })
  }

  return {
    filters: search.filters || [],
    sorters: search.sorters || [],
    pageNumber: search.pageNumber,
    saveFilters,
    saveSorters,
    savePageNumber,
    getFactorFilters,
    createCustomFactor,
    createFactorRequest,
    createFactorRequestResponse,
    updateCustomFactor,
    deleteCustomFactor,
    getFactors,
    getFactorsData,
    getFactorRequests,
    getFactorRequestsAsSupplier,
    factorRequestsAsSupplier,
    factorRequests,
    saveFactorRequest,
    applyFactorChanges,
    getFactorChangelogs,
  }
}

export default useFactorFilter
